import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

// JOY
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import Autocomplete from '@mui/joy/Autocomplete';
import Stack from '@mui/joy/Stack';
import Grid from '@mui/joy/Grid';


// Icons
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

// Actions
import { getSloAuthor, getSloMaterial, getSloTechnique, getSloTags, getSloType } from '../../actions/objects.actions';
import { addToSearchTab, removeFromSearchTab, removeFromSearchTabAll, setSearchTab } from '../../reducers/objects.reducer';

// Cnst
import { getFileSrc } from '../../middeware/prc.middleware';

//Images
import NoImage  from './../../images/noimg.png';



const webServiceURL = getFileSrc();

const SearchSideBar = ({closeSideBar}) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const objects = useSelector((state) => state.objects);

    const [searchOptions, setSearchOptions] = useState('all');
    const [search, setSearch] = useState('');
    const [author, setAuthor] = useState([]);
    const [material, setMaterial] = useState([]);
    const [technique, setTechnique] = useState([]);
    const [type, setType] = useState([]);
    const [tags, setTags] = useState([]);
    const [period, setPeriod] = useState([0,0]);
    
    useEffect(() => {
        const obj = {type: 'period', value: period[0] + '|' + period[1]};
        let delayFunc = null;
        if(period[0] > 0 | period[1] > 0){
            delayFunc = setTimeout(() => dispatch(addToSearchTab(obj)), 1000)
        }
        return () => clearTimeout(delayFunc)
    },[period]);

    useEffect(() => {
        dispatch(getSloAuthor());
        dispatch(getSloMaterial());
        dispatch(getSloTechnique());
        dispatch(getSloTags());
        dispatch(getSloType());

        return () => dispatch(setSearchTab([]));
    },[dispatch]);

    useEffect(() => {
        const param = searchParams.get('search');
        if(param !== null && param !== ''){
            setSearch(param);
            handleSearchInput(param);
        }
    },[searchParams,dispatch]);


    const handleSearchInput = (newSearch) => {
        const val = newSearch !== undefined ? newSearch : search;
        const obj = {type: 'search', value: searchOptions + '|' + val}
        dispatch(addToSearchTab(obj));
    }

    const handleRemoveSearchInput = () => {
        const obj = {type: 'search', value: searchOptions + '|' + search}
        dispatch(removeFromSearchTab(obj));
        setSearch('');
    }

    const handlePresEnterOnSearchInput = (ev) => {
        if(ev.key === 'Enter'){
            handleSearchInput();
        }
    }

    const handleChangeAuthor = (ev, newTab) => {
        handleChangeTab(author, newTab, 'author');
        setAuthor(newTab);
    }

    const handleChangeMaterial = (ev, newTab) => {
        handleChangeTab(material, newTab, 'material');
        setMaterial(newTab);
    }
    
    const handleChangeType = (ev, newTab) => {
        handleChangeTab(type, newTab, 'type');
        setType(newTab);
    }

    const handleChangeTags = (ev, newTab) => {
        handleChangeTab(tags, newTab, 'tags');
        setTags(newTab);
    }

    const handleChangeTechnique = (ev, newTab) => {
        handleChangeTab(technique, newTab, 'technique');
        setTechnique(newTab);
    }

    const handleChangeTab = (destTab, newTab, type) => {
        if(newTab.length === 0){
            dispatch(removeFromSearchTabAll(type))
        }else if(destTab.length > newTab.length){
            let tab = destTab.filter(x => !newTab.includes(x));
            if(tab.length > 0){
                dispatch(removeFromSearchTab({type: type, value: tab[0].name}));
            }
        }else if(newTab.length > destTab.length){
            let tab = newTab.filter(x => !destTab.includes(x));
            if(tab.length > 0){
                dispatch(addToSearchTab({type: type, value: tab[0].name}));
            }
        }
    }

    return <Sheet variant="outlined" sx={{ p:'1rem 0 2rem 0', borderRadius: 12, m: '0.5rem 0 0.5rem 0.5rem', height: 'calc(100vh - 90px)' }}>
                <Typography level="body1" fontWeight='lg' sx={{letterSpacing: '0.1rem', px: '1rem', mb: '0.5rem', display: 'flex'}}>
                    FILTRY 
                <IconButton variant='plain' size="sm" onClick={closeSideBar} sx={{ml: 'auto', display: {xs: 'flex', md: 'none'}}}><CloseIcon /></IconButton></Typography>
                <Box sx={{p: '0 1rem', width: 'calc(100% - 1rem)', height: 'calc(100% - 2rem)', overflowY:'auto'}}>
                    <Typography level="body2" sx={{mb: '0.5rem'}}>
                        Wprowadź poniżej kryteria wyszukiwania. Po odnalezieniu obiektów, które Cię interesują kliknij przycisk <Typography color="primary">Dodaj do koszyka</Typography> na danym obiekcie aby zapisać obiekt w koszyku kwerendy. Jeżeli Twój koszyk jest gotowy kliknij na jego ikonę w górnym prawym rogu strony i przejdź do podsumowania kwerendy.
                    </Typography>
                    <Divider orientation="horizontal" sx={{m: '1rem 0'}} />
                    <FormControl sx={{mb: '0.5rem'}}>
                        <Input
                            placeholder='Wpisz frazę do wyszukania'
                            endDecorator={
                                <>
                                    {objects.searchTab !== null && objects.searchTab.findIndex(el => el.type==='search') > -1 && 
                                        <IconButton color="danger" sx={{mr: '0.4rem'}} onClick={handleRemoveSearchInput}><CloseIcon /></IconButton>
                                    }
                                    <Button variant="soft" onClick={handleSearchInput}>Szukaj</Button>
                                </>
                            }
                            value={search}
                            onKeyDown={handlePresEnterOnSearchInput}
                            onChange={(ev) => setSearch(ev.target.value)}
                            onFocus={(ev) => ev.target.select()}
                        />
                    </FormControl>
                    <FormControl>
                        <RadioGroup 
                            sx={{ flexDirection: 'row', flexWrap: 'wrap', rowGap: '0.5rem', columnGap: '0.5rem'}} 
                            value={searchOptions} 
                            onChange={(ev) => {setSearchOptions(ev.target.value)}}
                        >
                            <Radio
                                value="all"
                                size="sm"
                                variant="outlined"
                                label="Pełnotekstowe"
                                sx={{marginInlineStart: '0', marginBlockStart: 0}}
                            />
                            <Radio
                                value="name"
                                size="sm"
                                variant="outlined"
                                label="Tytuł/Nazwa"
                                sx={{marginInlineStart: '0', marginBlockStart: 0}}
                            />
                            <Radio
                                value="description"
                                size="sm"
                                variant="outlined"
                                label="Opis"
                                sx={{marginInlineStart: '0', marginBlockStart: 0}}
                            />
                            <Radio
                                value="signature"
                                size="sm"
                                variant="outlined"
                                label="Znak inwenatrzowy"
                                sx={{marginInlineStart: '0', marginBlockStart: 0}}
                            />
                        </RadioGroup>
                    </FormControl>
                    <Divider orientation="horizontal" sx={{m: '1rem 0'}} />
                        <Grid container spacing={1} sx={{width: '90%'}}>
                            <Grid sm={6}>
                                <FormControl >
                                    <FormLabel  id="muzeo-periodfrom-label" htmlFor="muzeo-periodfrom">
                                        Okres od roku:
                                    </FormLabel>
                                    <Input
                                        id="muzeo-periodfrom"
                                        size="sm"
                                        onChange={(ev) => setPeriod([parseInt(ev.target.value), period[1]])}
                                        value={period[0] !== 0 ? period[0] : ''}
                                        type="number"
                                        onFocus={(ev) => ev.target.select()}
                                        endDecorator={period[0] !== 0 ? <IconButton onClick={() => setPeriod([0, period[1]])}><CloseIcon /></IconButton> : ''}
                                    />
                                    </FormControl>
                            </Grid>
                            <Grid sm={6}>
                                <FormControl>
                                    <FormLabel  id="muzeo-periodto-label" htmlFor="muzeo-periodto">
                                        do roku:
                                    </FormLabel>
                                    <Input
                                        id="muzeo-periodto"
                                        size="sm"
                                        onChange={(ev) => setPeriod([period[0],parseInt(ev.target.value)])}
                                        value={period[1] !== 0 ? period[1] : ''}
                                        type="number"
                                        onFocus={(ev) => ev.target.select()}
                                        endDecorator={period[1] !== 0 ? <IconButton onClick={() => setPeriod([period[0],0])}><CloseIcon /></IconButton> : ''}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    <Divider orientation="horizontal" sx={{m: '1rem 0'}} />
                    <FormControl sx={{mb: 1}}>
                        <FormLabel  id="select-muzeo-author-label" htmlFor="select-muzeo-author">
                            Autor/wytwórnia
                        </FormLabel>
                        <Autocomplete
                            multiple
                            size="sm"
                            id="select-muzeo-author"
                            placeholder="Wybierz autora"
                            options={objects.sloData.author.list}
                            value={author}
                            getOptionLabel={(option) => option.name}
                            onChange={handleChangeAuthor}
                        />
                    </FormControl>
                    <FormControl sx={{mb: 1}}>
                        <FormLabel  id="select-muzeo-type-label" htmlFor="select-muzeo-type">
                            Rodzaj
                        </FormLabel>
                        <Autocomplete
                            multiple
                            size="sm"
                            id="select-muzeo-type"
                            placeholder="Wybierz rodzaj"
                            options={objects.sloData.type.list}
                            value={type}
                            getOptionLabel={(option) => option.name}
                            onChange={handleChangeType}
                        />
                    </FormControl>
                    <FormControl sx={{mb: 1}}>
                        <FormLabel  id="select-muzeo-material-label" htmlFor="select-muzeo-material">
                            Materiał/Tworzywo
                        </FormLabel>
                        <Autocomplete
                            multiple
                            size="sm"
                            id="select-muzeo-material"
                            placeholder="Wybierz materiał"
                            options={objects.sloData.material.list}
                            getOptionLabel={(option) => option.name}
                            value={material}
                            onChange={handleChangeMaterial}
                        />
                    </FormControl>
                    <FormControl sx={{mb: 1}}>
                        <FormLabel  id="select-muzeo-technique-label" htmlFor="select-muzeo-technique">
                            Technika wykonania
                        </FormLabel>
                        <Autocomplete
                            multiple
                            size="sm"
                            id="select-muzeo-technique"
                            placeholder="Wybierz technikę"
                            options={objects.sloData.technique.list}
                            getOptionLabel={(option) => option.name}
                            value={technique}
                            onChange={handleChangeTechnique}
                        />
                    </FormControl>
                    <FormControl sx={{mb: 1}}>
                        <FormLabel  id="select-muzeo-tags-label" htmlFor="select-muzeo-tags">
                            Słowa kluczowe
                        </FormLabel>
                        <Autocomplete
                            multiple
                            size="sm"
                            id="select-muzeo-tags"
                            placeholder="Wybierz słowo"
                            options={objects.sloData.tags.list}
                            getOptionLabel={(option) => option.name}
                            value={tags}
                            onChange={handleChangeTags}
                        />
                    </FormControl>
                </Box>
            </Sheet>
}

export default SearchSideBar;