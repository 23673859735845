import { createAsyncThunk } from '@reduxjs/toolkit';

import {runPrc} from '../middeware/prc.middleware';

export const loginUser = createAsyncThunk(
  'auth/login',
  async (data, thunkApi) => {
        let wyn = {error: true, text: 'Nie udało się zalogować', data: []};

        await runPrc("pass_log", "e_login", [data.userLogin,data.userPassword]).then((data) => {
            if(data.length > 0 & data[0].WYNIK > 0){
                wyn = {error: false, text: data[0].UWAGA, data: data[0]}
            }else if(data.length > 0 & data[0].WYNIK === 0){
                wyn = {error: true, text: data[0].UWAGA, data: []}
            }else{
                wyn = {error: true, text: 'Nie udało się zalogować', data: []}
            }
        });

        return wyn;
  }
)

