import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// JOY
import Input from '@mui/joy/Input';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';

// Components


// Actions
import { getList } from '../../actions/objects.actions';
import { addToList, removeFromList } from '../../reducers/checkout.reducer';

// Icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';

const Pagination = ({sx={}, onPageChange, page, pageSize}) => {

    const [actPage, setActPage] = useState(page);

    const handlePageChange = () => {
        let sendPage = actPage;
        if(actPage > pageSize){
            sendPage = pageSize;
            setActPage(pageSize);
        }else if(actPage < 1){
            sendPage = 1;
            setActPage(1);
        }
        onPageChange(sendPage);
    }

    const handleNextPage = () => {
        if( pageSize > actPage){
            onPageChange(actPage + 1);
            setActPage(actPage + 1);
        }
    }

    const handlePrevPage = () => {
        if( actPage > 1){
            onPageChange(actPage - 1);
            setActPage(actPage - 1);
        }
    }

    const handleFirstPage = () => {
        onPageChange(1);
        setActPage(1);
    }

    const handleLastPage = () => {
        onPageChange(pageSize);
        setActPage(pageSize);
    }

    const handlePresEnter = (ev) => {
        if(ev.key === 'Enter'){
            handlePageChange();
        }
    }


    return (<Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={sx}
            >
                <IconButton color="neutral" size="sm" onClick={handleFirstPage} disabled={page === 1}>
                    <FirstPageIcon />
                </IconButton>
                <IconButton color="neutral" size="sm" onClick={handlePrevPage} disabled={page === 1}>
                    <NavigateBeforeIcon />
                </IconButton>
                <Input
                    type="number"
                    size="sm"
                    value={actPage}
                    onChange={(ev,value) => setActPage(parseInt(ev.target.value))}
                    onKeyDown={handlePresEnter}
                    endDecorator={
                        <IconButton onClick={handlePageChange}><KeyboardTabIcon /></IconButton>
                    }
                    slotProps={{
                        input: {
                            min: 1,
                            max: pageSize,
                        },
                    }}
                />
                <Typography textColor="neutral.500" fontSize="sm" fontWeight="lg"> / {pageSize}</Typography>
                <IconButton color="neutral" size="sm" onClick={handleNextPage} disabled={page === pageSize}>
                    <NavigateNextIcon />
                </IconButton>
                <IconButton color="neutral" size="sm" onClick={handleLastPage} disabled={page === pageSize}>
                    <LastPageIcon />
                </IconButton>
            </Stack>
               
    );
}

export default Pagination;