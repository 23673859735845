import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// JOY
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import { grey } from '@mui/material/colors';
import { Divider } from '@mui/joy';

// Components
import ObjectCard from '../../features/ObjectCard/ObjectCard';
import ObjectCardList from '../../features/ObjectCard/ObjectCardList';
import ObjectCardSkeleton from '../../features/ObjectCard/ObjectCardSkeleton';
import ObjectCardListSkeleton from '../../features/ObjectCard/ObjectCardListSkeleton';
import SearchSideBar from '../../features/SearchSideBar/SearchSideBar';
import Pagination from '../../features/Pagination/Pagination';


// Actions
import { getList } from '../../actions/objects.actions';
import { setPage, setOnPage, setSort, setView, setDeatils } from '../../reducers/objects.reducer';
import { addToList, removeFromList } from '../../reducers/checkout.reducer';

// Icons
import WindowIcon from '@mui/icons-material/Window';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';


const SearchObjects = () => {

    const dispatch = useDispatch();
    const objects = useSelector((state) => state.objects);
    const checkout = useSelector((state) => state.checkout);

    const [showFlters, setShowFilters] = useState(false);

    let skeleton = [];
    for(let i= 0; i < objects.listOptions.onPage; i++){
        skeleton.push('skeleton-'+i);
    }

    useEffect(() => {
        dispatch(getList());
    }, [dispatch, objects.listOptions.page, objects.listOptions.onPage, objects.listOptions.sort]);

    useEffect(() => {
        if(objects.searchTab !== null){
            dispatch(getList());
        }
    }, [dispatch, objects.searchTab]);

    const handleClickCard = (ev) => {
        const ref_nr = parseInt(ev.currentTarget.getAttribute("data-ref"));
        if(ref_nr !== undefined && ref_nr !== 0 && ref_nr !== null){
            const ndx = objects.list.findIndex(el => el.ref_nr === ref_nr);
            if(ndx > -1){
                dispatch(setDeatils({data: objects.list[ndx], dialog:true}));
            }
        }
    }

    const addToCheckout = (ev) => {
        const ref_nr = parseInt(ev.currentTarget.getAttribute("data-ref"));
        if(ref_nr !== undefined && ref_nr !== 0 && ref_nr !== null){
            const ndx = objects.list.findIndex(el => el.ref_nr === ref_nr);
            if(ndx > -1){
                dispatch(addToList({...objects.list[ndx]}));
            }
        }
    }

    const removeFromCheckout = (ev) => {
        const ref_nr = parseInt(ev.currentTarget.getAttribute("data-ref"));
        if(ref_nr !== undefined && ref_nr !== 0 && ref_nr !== null){
            const ndx = checkout.list.findIndex(el => el.ref_nr === ref_nr);
            if(ndx > -1){
                dispatch(removeFromList(ndx));
            }
        }
    }

    const handlePageChange = (page) => {
        dispatch(setPage(page))
    }

    const handleSortChange = (sort) => {
        dispatch(setSort(sort))
    }

    const handleOnPageChange = (onPage) => {
        dispatch(setOnPage(onPage))
    }

    return (
        <Box sx={{display: 'flex', height:'calc(100vh - 68px)', backgroundColor: grey[100]}}>
            <Grid container columns={16} sx={{ flexGrow: 1, m: 0 }}>
                <Grid xl={3} lg={4} md={6} sm={8} xs={16} sx={{display: {xs: (showFlters ? 'flex' : 'none'), sm: 'inherit'}}}>
                        <SearchSideBar closeSideBar={() => setShowFilters(false)}/>
                </Grid>
                <Grid xl={13} lg={12} md={10} sm={8} xs={16}>
                    <Box sx={{ display: {xs: (showFlters ? 'none' : 'flex'),md: 'flex'}, p: '1rem', alignItems: 'center', rowGap: '1rem' }}>
                        <IconButton onClick={() => dispatch(setView('grid'))} size="sm" color={ objects.listOptions.view !== "grid" ? "neutral" : 'primary'} sx={{mr: '0.2rem', display: {xs: 'none', sm:'flex'}}}><WindowIcon /></IconButton>
                        <IconButton onClick={() => dispatch(setView('list'))} size="sm" color={ objects.listOptions.view !== "list" ? "neutral" : 'primary'} sx={{mr: '1rem', display: {xs: 'none', sm:'flex'}}}><ViewStreamIcon /></IconButton>
                        <Divider orientation='vertical' sx={{mr: '1rem', display: {xs: 'none', sm:'flex'}}} />
                        <Typography textColor="neutral" fontSize="sm" fontWeight="lg" sx={{mr: '1rem', display: {xs: 'none', sm:'flex'}}}>Pozycji: {objects.listOptions.sizeAll}</Typography>
                        <Divider orientation='vertical' sx={{mr: '1rem', display: {xs: 'none', sm:'flex'}}} />
                        <FormControl sx={{ flexDirection:'row', alignItems: 'center', mr: '1rem', display: {xs: 'none', sm:'inherit'}  }}>
                            <FormLabel sx={{alignSelf: 'auto', mr: '0.5rem', mb: 0}} id="select-muzeo-position-label" htmlFor="select-muzeo-position">
                                Pozycji na stronie
                            </FormLabel>
                            <Select
                                color="neutral"
                                placeholder="Choose one…"
                                size="sm"
                                variant="plain"
                                value={objects.listOptions.onPage}
                                onChange={(ev,value) => handleOnPageChange(parseInt(value))}
                                slotProps={{
                                    button: {
                                    id: 'select-muzeo-position',
                                    'aria-labelledby': 'select-muzeo-position-label select-muzeo-position',
                                    },
                                }}
                            >
                                <Option value={20}>20</Option>
                                <Option value={40}>40</Option>
                                <Option value={60}>60</Option>
                                <Option value={80}>80</Option>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ flexDirection:'row', alignItems: 'center', display: {xs: 'none', sm:'inherit'}}}>
                            <FormLabel sx={{alignSelf: 'auto', mr: '0.5rem', mb: 0, display: {md: 'none', lg:'flex'}}} id="select-muzeo-sort-label" htmlFor="select-muzeo-sort">
                                Sortuj
                            </FormLabel>
                            <Select
                                color="neutral"
                                placeholder="Choose one…"
                                size="sm"
                                variant="plain"
                                value={objects.listOptions.sort}
                                onChange={(ev,value) => handleSortChange(value)}
                                slotProps={{
                                    button: {
                                    id: 'select-muzeo-sort',
                                    'aria-labelledby': 'select-muzeo-sort-label select-muzeo-sort',
                                    },
                                }}
                            >
                                <Option value="name asc">Nazwa/Tytuł (A-Z)</Option>
                                <Option value="name desc">Nazwa/Tytuł (Z-A)</Option>
                                <Option value="make asc">Data dołączenia (od najstarszych)</Option>
                                <Option value="make desc">Data dołączenia (od najnowszych)</Option>
                            </Select>
                        </FormControl>
                        <IconButton size="sm" variant="solid" color="neutral" onClick={() => setShowFilters(!showFlters)} sx={{display: {xs: 'flex', md: 'none'}}}><SearchIcon /></IconButton>
                        <Pagination sx={{ml: 'auto'}} page={objects.listOptions.page} pageSize={objects.listOptions.pageSize} onPageChange={handlePageChange} />
                    </Box>
                    <Grid container spacing={2} columns={16} sx={{ display: {xs: (showFlters ? 'none' : 'flex'),md: 'flex'}, flexGrow: 1, m: 0,  overflow: 'auto', height:'calc(100vh - 130px)' }} >
                        { objects.listOptions.view ==='grid' && objects.listLoading === false && objects.list !== null && objects.list.length > 0 && objects.list.map((el, index) => {
                                return <Grid xs={16} sm={16} md={8} lg={5.333333} xl={3.2} key={el.reference}>
                                            <ObjectCard item={el} onAddButtonClick={addToCheckout} onRemoveButtonClick={removeFromCheckout} onClick={handleClickCard} />
                                        </Grid>
                                })
                        }
                        { objects.listOptions.view ==='list' && objects.listLoading === false && objects.list !== null && objects.list.length > 0 && objects.list.map((el, index) => {
                                return <Grid xs={16} key={el.reference}>
                                            <ObjectCardList item={el} onAddButtonClick={addToCheckout} onRemoveButtonClick={removeFromCheckout} onClick={handleClickCard} />
                                        </Grid>
                                })
                        }
                        { objects.listOptions.view ==='grid' && objects.listLoading  && skeleton.map((el, index) => {
                                return  <Grid xs={16} sm={16} md={8} lg={5.333333} xl={3.2}  key={el}>
                                            <ObjectCardSkeleton />
                                        </Grid>
                                })
                        }    
                        { objects.listOptions.view ==='list' && objects.listLoading  && skeleton.map((el, index) => {
                                return  <Grid xs={16} key={el}>
                                            <ObjectCardListSkeleton />
                                        </Grid>
                                })
                        }    
                    </Grid>
                    {objects.listLoading === false && (objects.list === null || objects.list.length === 0) && 
                            <Grid sm={8} sx={{m: '2rem auto'}}>
                                <Typography level="h4" textColor="neutral">
                                    Niestety nie znaleziono obiektów spełniających podane kryteria. Proszę zmienić kryteria i wyszukać ponownie.
                                </Typography>
                            </Grid>
                        }
                </Grid>
            </Grid>
        </Box>
    );
}

export default SearchObjects