import { useSelector } from 'react-redux';

// JOY
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';

// Actions
import { setDeatils } from '../../reducers/objects.reducer';

// Cnst
import { getFileSrc } from '../../middeware/prc.middleware';

//Images
import NoImage  from './../../images/noimg.png';

// Icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Divider } from '@mui/joy';

const webServiceURL = getFileSrc();

const ObjectCardList = ({item, onAddButtonClick, onRemoveButtonClick, onClick}) => {
    const {list} = useSelector(state => state.checkout);

    const ifRemoveButton = list.findIndex(el => el.ref_nr === item.ref_nr) > -1;

    return  <Card variant="soft" orientation="horizontal" sx={{ width: {sm: '100%', md: '70%'}, ml: {md: 3}, boxShadow: 'none', bgcolor: 'initial', '--Card-padding': '0px', }}>
                    {/* <AspectRatio 
                        // variant="outlined" 
                        sx={{ minWidth: '100px', maxWidth: '200px', mx: 2 }} 
                        objectFit="cover" 
                        data-ref={item.ref_nr} 
                        onClick={onClick} 
                    > */}
                    <Box sx={{width: '200px'}}>
                        <img
                            src={item.mainFile !== null && item.mainFile !== '' ? (webServiceURL + '/' +item.mainFile.id_min) : NoImage}
                            srcSet={item.mainFile !== null && item.mainFile !== '' ? (webServiceURL + '/' +item.mainFile.id) : NoImage}
                            loading="lazy"
                            alt={`Miniturka karty obiektu: ${item.name}`}
                            style={{maxHeight: '200px', width: '200px', borderBottomLeftRadius: '12px', borderTopLeftRadius: '12px', objectFit: 'cover'}}
                        />
                    </Box>
                    {/* </AspectRatio> */}
                        <Box sx={{cursor: 'pointer', ml: 2, width: '100%', display: 'flex', flexDirection:'column'}}>
                            <Divider sx={theme => ({width: '100%', "--Divider-thickness": '1px'})} />
                            <Typography noWrap level="h2" fontSize="md" sx={{ my: 1 }}>
                                {item.name !== '' ? item.name : '---'}
                            </Typography>
                            <Typography noWrap level="body2">{item.author !== '' ? item.author : '---'}</Typography>
                            <Typography noWrap level="body2">{item.period !== '' ? item.period : '---'}</Typography>
                            <Box sx={{ mt:'auto', display: 'flex', flexDirection: 'column'}}>
                                <Box sx={{ display: 'flex', mt: 1}}>
                                    {ifRemoveButton === false ?
                                        <Button
                                            variant="soft"
                                            color="neutral"
                                            size="sm"
                                            aria-label="Dołącz obiekt do koszyka kwerendy"
                                            startDecorator={<AddIcon />}
                                            sx={{ height: 'fit-content' }}
                                            data-ref={item.ref_nr}
                                            onClick={onAddButtonClick}
                                        >
                                            Do koszyka
                                        </Button>
                                    :
                                        <Button
                                            variant="soft"
                                            size="sm"
                                            color="danger"
                                            aria-label="Usuń obiekt z koszyka kwerendy"
                                            startDecorator={<RemoveIcon />}
                                            sx={{ height: 'fit-content' }}
                                            data-ref={item.ref_nr}
                                            onClick={onRemoveButtonClick}
                                        >
                                            Usuń z koszyka
                                        </Button>
                                    }
                                    <Button
                                            variant="soft"
                                            color="neutral"
                                            size="sm"
                                            aria-label="Podgląd obiektu"
                                            startDecorator={<RemoveRedEyeIcon />}
                                            sx={{ ml: 2, height: 'fit-content' }}
                                            data-ref={item.ref_nr} 
                                            onClick={onClick} 
                                        >
                                            Podgląd
                                    </Button>
                                </Box>
                                <Divider sx={theme => ({mt: 1, width: '100%', "--Divider-thickness": '1px'})} />
                            </Box>
                        </Box>
                </Card>
}

export default ObjectCardList;