import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';


// JOY
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import Stack from '@mui/joy/Stack';
import Badge from '@mui/joy/Badge';
import IconButton from '@mui/joy/IconButton';
import AspectRatio from '@mui/joy/AspectRatio';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';

// Components
import Drawer from '../Drawer/Drawer';
import CheckoutDrawer from '../CheckoutDrawer/CheckoutDrawer';
import ObjectDetailsDialog from '../ObjectDetails/ObjectDetailsDialog';

// Actions
import { logout } from '../../reducers/auth.reducer';

//Images
// import Logo  from './../../images/logo.jpg';
import Logo  from './../../images/logo_muzdob.png';
// import LogoXS  from './../../images/muzeo_logo50.jpg';
import LogoXS  from './../../images/muzdob_logo50.jpg';

// Icons import
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import SearchIcon from '@mui/icons-material/Search';

export default function TopBar() {
    const navigate   =  useNavigate();
    const dispatch   = useDispatch();
    const {cartSize} = useSelector((state) => state.checkout);
    const[checkoutDrawerOpen, setCheckoutDrawerOpen] = useState(false);
    const [search, setSearch] = useState('');
    const location = useLocation();

    const handlePresEnterOnSearchInput = (ev) => {
        if(ev.key === 'Enter'){
            handleSearch();
        }
    }

    const handleSearch = () => {
        navigate('find?search='+search);
        setSearch('');
    }

    const handleLogout = () => {
        dispatch(logout());
    }
    
  return (
    <>
    <Box sx={{width: '100%', height: '65px', borderBottom: '1px solid #c2c2c2', display: 'flex', alignItems: 'center'}}>
            <Link
              textColor="inherit"
              underline="none"
              fontWeight="md"
              onClick={() => navigate('/')}
              sx={{width:'fit-content', display: {xs: 'none', md: 'flex'}}}
            >
                <img src={Logo} srcSet={Logo}  style={{maxWidth: 200, maxHeight:55, margin: '0.5rem 0.5rem 0.5rem 1rem', borderRadius: '12px'}}  alt="Logo systemu Muzeo" />
        
            </Link>
            <Link
              textColor="inherit"
              underline="none"
              fontWeight="md"
              onClick={() => navigate('/')}
              sx={{width:'fit-content', display: {xs: 'flex', md: 'none'}}}
            >
                <img src={Logo} srcSet={LogoXS}  style={{maxWidth: 200, maxHeight:45, margin: '0.5rem 0.5rem 0.5rem 1rem', borderRadius: '12px'}}  alt="Logo systemu Muzeo" />
        
            </Link>
            <Stack
                direction="row"
                spacing={2}
                justifyContent="start"
                sx={{ width: '100%', ml: '1rem', display: {xs: 'none', md: 'flex'} }}
            >
                <Button onClick={() => navigate('/find')} variant="plain" > Katalog </Button> 
                <Button onClick={() => navigate('/myquery')} variant="plain" > Moje kwerendy </Button> 
            </Stack>
            <Stack
                direction="row"
                spacing={2}
                justifyContent="end"
                sx={{ width: '100%', mr:'1rem' }}
            >
                {/* {location.pathname !== '/find' &&
                        <Input
                            placeholder='Wpisz frazę do wyszukania'
                            startDecorator={<SearchIcon />}
                            endDecorator={
                                <>
                                    {search !== '' && <Button variant="soft" sx={{mr: '0.4rem'}} onClick={handleSearch}>Szukaj</Button>}
                                    <Button variant="plain" onClick={() => navigate('/find')}>Katalog</Button>
                                </>      
                            }
                            // size="sm"
                            variant="soft"
                            value={search}
                            onKeyDown={handlePresEnterOnSearchInput}
                            onChange={(ev) => setSearch(ev.target.value)}
                            onFocus={(ev) => ev.target.select()}
                        />
                } */}
                    {/* <Button variant="plain" onClick={() => navigate('/find')}>Katalog</Button> */}
                    <IconButton onClick={() => setCheckoutDrawerOpen(true)} variant="plain"> 
                        <Badge badgeContent={cartSize} variant="solid"><ShoppingBasketIcon /> </Badge>
                    </IconButton > 
                <Button onClick={handleLogout} variant="plain"> Wyloguj </Button> 
            </Stack>
    </Box>
    <ObjectDetailsDialog />
    <Drawer
        isOpen={checkoutDrawerOpen}
        onClose={() => setCheckoutDrawerOpen(false)}
        position="right"
    >   
            <div tabIndex="1">
                <CheckoutDrawer onDrawerClose={() => setCheckoutDrawerOpen(false)} />
            </div>
    </Drawer>
   </>
  );
}
