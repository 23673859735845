import {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";


// JOY
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { grey } from '@mui/material/colors';
import Divider from '@mui/joy/Divider';
import Grid from '@mui/joy/Grid';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import Table from '@mui/joy/Table';
import AspectRatio  from '@mui/joy/AspectRatio';
import { IconButton, ListDivider } from "@mui/joy";
import Button from '@mui/joy/Button';
import Switch from '@mui/joy/Switch';


// Components
import QueryStatus from "../../features/QueryStatus/QueryStatus";
import QueryInfo from "../../features/QueryInfo/QueryInfo";
import QueryObjectsTable from "../../features/QueryObjectsTable/QueryObjectsTable";
import ConfirmDialog from "../../features/ConfirmDialog/ConfirmDialog";
import Skeleton from "react-loading-skeleton";
import { format, addDays } from "date-fns";
import { pl } from 'date-fns/locale';
import parse from 'html-react-parser';

// Icons
import PrintIcon from '@mui/icons-material/Print';
import ImageIcon from '@mui/icons-material/Image';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

// Cnst
import { getFileSrc } from '../../middeware/prc.middleware';

// Actions
import { querendRecordGet, querendAgreementListGet } from "../../actions/querend.actions";

import { setDeatils } from "../../reducers/objects.reducer";

const webServiceURL = getFileSrc();

const Query = () => {

    const query = useSelector(state => state.query);
    const dispatch = useDispatch();
    const { id }    = useParams();
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if(id !== ''){
            dispatch(querendRecordGet(id));
        }
    },[id, refresh, dispatch])
    
    useEffect(() => {
            if(query.data !== null && query.data.query !== undefined && query.data.query.ref_nr !== undefined ){
                dispatch(querendAgreementListGet(query.data.query.ref_nr));
            }
    },[dispatch, query.data]);

    console.log('QUERY',query);

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', height:'calc(100vh - 68px)',overflow: 'auto', alignItems: 'center', backgroundColor: grey[200]}}>
            <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height:'calc(100vh - 68px)',
                        p: {xs: 0, md: '1rem'},
                        width: {
                            xs: '100%',
                            md: 900,
                            xl: 1400
                        },
                    }}
                >
                {query.loading === false && query.data !== null &&
                    <>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12} md={7} >
                            <QueryInfo id={id} />
                        </Grid>
                        <Grid xs={12} sm={12} md={5}>
                            <Typography fontWeight="lg" textColor="text.primary" textTransform='uppercase' sx={{mt: 2, mb: 1, ml: {xs: 1, md: 0}}}>
                                        Historia działań na kwerendzie ({query.data.history !== null ? query.data.history.length : ''})
                            </Typography>
                            <Sheet
                                variant="outlined"
                                sx={{
                                    width: '100%',
                                    maxHeight: '100%',
                                    borderRadius: 'sm',
                                    pt: 2,
                                    pb: 4,
                                    px: {xs: 1, md: 2},
                                    mb: 2
                                }}
                            >
                                {query.data.history !== undefined && query.data.history !== null && query.data.history.length > 0 ?
                                    <List
                                        aria-labelledby="muzeo-query-list-history"
                                    >
                                        {query.data.history.map(item => (
                                            <>
                                                <ListItem>
                                                    <ListItemContent>
                                                        <Typography>{item.name} <QueryStatus status={item.status} size="xs" sx={{mb: '0.5rem'}}/></Typography>
                                                        <Typography level="body2" noWrap mb={0.5}>
                                                            {`${format(new Date(item.date), "dd MMMM yyyy", {locale: pl})} o godz. ${item.time} - ${item.person}`}
                                                        </Typography>
                                                        <Typography level="body2">{item.description}</Typography>
                                                    </ListItemContent>
                                                </ListItem>
                                                <ListDivider />
                                            </>
                                        ))}
                                    </List>
                                :
                                    <Typography level="h5" textColor="text.tertiary">BRAK HISTORII W KWEENDZIE</Typography>
                                }
                            </Sheet>
                        </Grid>
                    </Grid>
                        
                        <Typography fontWeight="lg" textColor="text.primary" textTransform='uppercase' sx={{ml: {xs: 1, md: 0}}}>
                                       Lista obiektów ({query.data.objects !== null ? query.data.objects.length : ''})
                        </Typography>
                        <Sheet
                            variant="outlined"
                            sx={{
                                width: '100%',
                                maxHeight: '100%',
                                borderRadius: 'sm',
                                pt: 2,
                                pb: 4,
                                px: {xs: 0, md: 2},
                                my: 1,
                            }}
                        >
                            {query.data.objects !== undefined && query.data.objects !== null && query.data.objects.length > 0 ?
                                <QueryObjectsTable id={id} refresh={() => setRefresh(refresh + 1)}/>
                            :
                                <Typography level="h5" textColor="text.tertiary">BRAK OBIEKTÓW W KWEENDZIE</Typography>
                            }
                        </Sheet>

                        {/* {query.agreementList !== null && query.agreementList.length > 0 &&
                            <>
                                <Typography fontWeight="lg" textColor="text.primary" textTransform='uppercase' sx={{ml: {xs: 1, md: 0}}}>
                                            Umowy
                                </Typography>
                                <Sheet
                                    variant="outlined"
                                    sx={{
                                        width: '100%',
                                        maxHight: '100%',
                                        minHeight: 800,
                                        borderRadius: 'sm',
                                        pt: 2,
                                        pb: 4,
                                        px: {xs: 0, md: 2},
                                        my: 1,
                                        overflow: 'auto'
                                    }}
                                >
                                    {query.agreementList.map((el,index) =>{
                                        return <Box>{parse(el.JSON.slice(12,-14))}</Box>
                                    })}
                                </Sheet>
                            </>
                        }     */}
                        
                    </>  
                }
            </Box>

        </Box>
    );
}

export default Query;