import {useState, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';

// JOY
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import { Button, Divider, Stack } from "@mui/joy";
import { grey } from "@mui/material/colors";

// Components
import QueryStatus from './../../features/QueryStatus/QueryStatus';
import {settings} from './../../config';

// Actions
import { querendRecordGetList } from '../../actions/querend.actions';
import { useNavigate } from "react-router-dom";
import { logout } from '../../reducers/auth.reducer';

//Images
import backimg  from './../../images/loginb.jpg';
import Logo  from './../../images/logo.jpg';

// Icons import
import SearchIcon from '@mui/icons-material/Search';

const Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = useSelector(state => state.query);
    const auth = useSelector(state => state.auth);
    const [search, setSearch] = useState('');

    const handlePresEnterOnSearchInput = (ev) => {
        if(ev.key === 'Enter'){
            handleSearch();
        }
    }

    const handleSearch = () => {
        navigate('find?search='+search);
        setSearch('');
    }
    
    useEffect(() => {
        dispatch(querendRecordGetList());    
    }, [dispatch]);


    return (
        <Box sx={{display: 'flex', height:'100vh', alignItems: 'center', justifyContent:'center', backgroundColor: grey[200], backgroundImage: `url(${backimg})`, backgroundSize: 'cover'}}>
             <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mt: '-5rem',
                        width: {
                            xs: 'calc(100% - 32px)',
                            sm: 'calc(100% - 32px)',
                            md: 800,
                            xl: 1400
                        },
                    }}
                >
                   <Typography level="h1" fontWeight="sm" fontSize={{xs: 'xl3', md:'xl5'}}>KWERENDY <Typography fontWeight="xl" level="h1" fontSize={{xs: 'xl3', md:'xl5'}}>ZEWNĘTRZNE</Typography></Typography>
                   <Divider sx={{width: '100%', my: 1}} />
                   <Typography level="h3" fontWeight="sm" fontSize={{xs: 'xl', md:'xl3'}}>Dzień dobry <Typography level="h3" fontWeight="md" fontSize={{xs: 'xl', md:'xl3'}}>{auth.userInfo.userName}</Typography></Typography>
                   <Typography level="h4" fontWeight="sm" maxWidth={800} fontSize={{xs: 'lg', md:'xl2'}}>{settings.homeText}</Typography>
                   <Input
                        placeholder='Wpisz frazę i zacznij szukać'
                        startDecorator={<SearchIcon />}
                        sx={{maxWidth: 600, mt: 2}}
                        endDecorator={
                            <>
                                {search !== '' && <Button variant="soft" onClick={handleSearch}>Szukaj</Button>}
                            </>      
                        }
                        size="lg"
                        variant="plain"
                        value={search}
                        onKeyDown={handlePresEnterOnSearchInput}
                        onChange={(ev) => setSearch(ev.target.value)}
                        onFocus={(ev) => ev.target.select()}
                    />
                    <Stack direction={{xs: "column", md:"row"}} alignItems="center" spacing={2} sx={{mt: 2}}>
                        <Button variant="plain" sx={{width: 'fit-content'}} onClick={() => navigate('/find')}>Przejdź do katalogu</Button>
                        <Button variant="plain" sx={{width: 'fit-content'}} onClick={() => navigate('/myquery')}>Moje Kwerendy</Button>
                        <Button variant="plain" color="warning" sx={{width: 'fit-content'}} onClick={() => dispatch(logout())}>Wyloguj</Button>
                    </Stack>
                    
            </Box>
        </Box>
    );
}

export default Home