import {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";


// JOY
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { grey } from '@mui/material/colors';
import Divider from '@mui/joy/Divider';
import Grid from '@mui/joy/Grid';
import Button from '@mui/joy/Button';
import Switch from '@mui/joy/Switch';


// Components
import QueryStatus from "../../features/QueryStatus/QueryStatus";
import ConfirmDialog from "../../features/ConfirmDialog/ConfirmDialog";
import Skeleton from "react-loading-skeleton";
import { format, addDays } from "date-fns";
import { pl } from 'date-fns/locale';

// Icons
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

// Cnst
import { getFileSrc } from '../../middeware/prc.middleware';

// Actions
import { querendRecordGet, querendRecordCancel, querendRecordAccept } from "../../actions/querend.actions";

import { setDeatils } from "../../reducers/objects.reducer";

const webServiceURL = getFileSrc();

const QueryInfo = ({id}) => {

    const query = useSelector(state => state.query);
    const dispatch = useDispatch();

    const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);

    const handleAccept = () => {
        dispatch(querendRecordAccept(id));
        dispatch(querendRecordGet(id));
        setOpenCancelDialog(false);
    }

    const handleCancel = () => {
        dispatch(querendRecordCancel(id));
        dispatch(querendRecordGet(id));
        setOpenCancelDialog(false);
    }

    const explain = query.data.query.status.toLowerCase()=== 'aktywowana do oceny' ? 'W tym momencie analizujemy przesłaną przez Ciebie kwerendę. O odpowiedzi poinformujemy Cię w osobnym mailu. Możesz w każdej chwili anulować kwerendę.'
                    : query.data.query.status.toLowerCase()=== 'odpowiedziana' ? 'Twoje zapytanie zostało rozpatrzone i poniżej znajduje się odpowiedź. Proszę potwierdzić chęć kontynuowania procesu poprzez akcepację kwerendy (przycisk Akceptuj kwerendę), przy obiektach istnieje możliwość zdecydowania, które z nich finalnie chcesz wypożyczyć. Możesz również zrezygnować z kwerendy (przycisk: Anuluj kwerendę).' 
                    : query.data.query.status.toLowerCase()=== 'przyjęta' ? 'Kwerenda został przyjęta. Nasz dział teraz przygotuje odpowiednie dokumenty związane z wypożyczeniem. Będziemy się z Tobą kontaktować by uzgodnić szczegóły.'  
                    : query.data.query.status.toLowerCase()=== 'zakończona' ? 'Kwerenda została już rozpatrzona.' 
                    : query.data.query.status.toLowerCase()=== 'anulowana' ? 'Kwerenda nie została zaakceptowana.' 
                    :''

    return (
        <>
            <ConfirmDialog 
                open={openAcceptDialog} 
                onClose={() => setOpenAcceptDialog(false)} 
                title="Akceptacja kwerendy"
                text="Czy chcesz zaakceptować tę kwerendę? Akceptacja pozwoli finalnie zarezerwować obiekty i utworzyć dla nich odpowiednie dokumenty ruchu muzealiów." 
                acceptText="Tak, akceptuj"
                acceptFun={handleAccept}
            />
            <ConfirmDialog 
                open={openCancelDialog} 
                onClose={() => setOpenCancelDialog(false)} 
                title="Anulowanie kwerendy"
                text="Czy chcesz anulować tę kwerendę?" 
                acceptText="Tak, anuluj"
                acceptColor="danger"
                acceptFun={handleCancel}
            />
                            <Sheet
                                variant="outlined"
                                sx={{
                                    width: '100%',
                                    borderRadius: 'sm',
                                    pt: 2,
                                    pb: 4,
                                    px: {xs: 2, md: 4},
                                    my: 2,
                                }}
                            >
                                <Grid container>
                                    <Grid xs={12} md={12} lg={6}>
                                        <Typography level="h4" textColor="text.primary" mb={0.2}>
                                            Kwerenda: <Typography level="h4" fontWeight={'sm'} textColor="text.primary">{query.data.query.signature}</Typography> 
                                        </Typography>
                                        <Typography level="body1" textColor="text.tertiary">
                                            z dnia {format(new Date(query.data.query.queryDate), 'dd MMMM yyyy', {locale: pl})}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12} md={12} lg={6} sx={{display: 'flex', justifyContent: {xs: 'start', md: 'end'}, alignItems: {xs: 'start', md: 'end'}, flexDirection: 'column'}}>
                                        <QueryStatus status={query.data.query.status} size="md" sx={{mb: '0.5rem'}}/>
                                    </Grid>
                                </Grid>
                                <Typography color={query.data.query.status.toLowerCase()=== 'anulowana' ? "danger" : "primary"}>{explain}</Typography>
                                <Divider sx={{width: '100%', my: 2}} />   
                                <Typography fontWeight="lg">Okres</Typography>
                                <Typography>{`${format(new Date(query.data.query.startDate), 'dd LLLL yyyy', {locale: pl})} - ${format(new Date(query.data.query.endDate), 'dd LLLL yyyy', {locale: pl})}`}</Typography>
                                <Typography fontWeight="lg" mt={2}>Cel kwerendy</Typography>
                                <Typography>{query.data.query.name}</Typography>
                                <Typography fontWeight="lg" mt={2}>Opis</Typography>
                                <Typography>{query.data.query.description}</Typography>  
                                <Typography fontWeight="lg" mt={2}>Odpowiedź {query.data.query.answer !== '' ? ` z dnia ${format(new Date(query.data.query.answerDate), 'dd LLLL yyyy', {locale: pl})}` : ''}</Typography>
                                {query.data.query.answer !== '' ? 
                                    <Typography>{query.data.query.answer}</Typography> 
                                : 
                                    <Typography textColor="text.tertiary">-- brak odpwiedzi --</Typography>
                                }
                                <Typography fontWeight="lg" mt={2}>Osoba odpowiedzialna</Typography>
                                {query.data.query.responsPerson !== '' ? 
                                    <>
                                        <Typography>{query.data.query.responsPerson}</Typography> 
                                        {query.data.query.responsPersonTel !== '' && 
                                            <Typography><Typography fontWeight="lg">Tel: </Typography> {query.data.query.responsPersonTel}</Typography>
                                        }
                                        {query.data.query.responsPersonMail !== '' && 
                                            <Typography><Typography fontWeight="lg">Mail: </Typography> {query.data.query.responsPersonMail}</Typography> 
                                        }
                                    </>
                                : 
                                    <Typography textColor="text.tertiary">--nie przydzielono jeszcze osoby odpowiedzialnej do Twojej kwerendy--</Typography>
                                }
                                {query.data.query.if_cancel === 0 && query.data.query.if_end === 0 &&
                                    <Box sx={{mt: '1rem', display: 'flex', justifyContent: 'end'}}>
                                        { query.data.query.if_send === 1 && query.data.query.if_akc === 0 &&
                                            <Button startDecorator={<CheckIcon />} onClick={() => setOpenAcceptDialog(true)} size="sm" variant="soft" color="success" sx={{mr: '1rem'}}>
                                                Akceptuj kwerendę
                                            </Button>
                                        }
                                        <Button loading={query.actionLoading} startDecorator={<ClearIcon />} onClick={() => setOpenCancelDialog(true)} size="sm" variant="soft" color="danger">
                                            Anuluj Kwerendę
                                        </Button>
                                    </Box>
                                }
                            </Sheet> 
        </>             
    );
}

export default QueryInfo;