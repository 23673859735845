import { createSlice } from '@reduxjs/toolkit';

// Actions
import { 
    querendRecordGet, 
    querendRecordGetList, 
    querendRecordCancel, 
    querendRecordAccept, 
    querendPositionCancel, 
    querendAgreementListGet 
} from '../actions/querend.actions';

const initialState = {
  loading: false,
  data: null,
  list: [],
  listLoading: false,
  actionLoading: false,
  agreementList: null,
  error: ''
}

const querySlice = createSlice({
  name: 'query',
  initialState,
  reducers: {
    setData: (state, {payload}) => {
        let newObjects = state.data.objects;
        newObjects[payload].if_can = newObjects[payload].if_can === 1 ? 0 : 1;
        state.data = {...state.data, objects: newObjects};
    }, 
  },
  extraReducers: (builder) => {
    builder.addCase(querendRecordGet.pending, (state, { payload }) => {
        state.loading = true;
     })
    .addCase(querendRecordGet.fulfilled, (state, { payload }) => {
        state.loading = false;
        if(payload.error === false){
            state.data = payload.data;
        }else{
            payload.error = payload.text;
            state.data = null;
        }
    })
    .addCase(querendRecordGet.rejected, (state, { payload }) => {
        state.loading = false;
    })
    .addCase(querendRecordGetList.pending, (state, { payload }) => {
        state.listLoading = false;
    })
    .addCase(querendRecordGetList.fulfilled, (state, { payload }) => {
        state.listLoading = false;
        if(payload.error === false){
            state.list = payload.data;
        }else{
            payload.error = payload.text;
            state.list = [];
        }
    })
    .addCase(querendRecordGetList.rejected, (state, { payload }) => {
        state.listLoading = false;
    })
    .addCase(querendRecordCancel.pending, (state, { payload }) => {
        state.actionLoading = false;
    })
    .addCase(querendRecordCancel.fulfilled, (state, { payload }) => {
        state.actionLoading = false;
    })
    .addCase(querendRecordCancel.rejected, (state, { payload }) => {
        state.actionLoading = false;
    })
    .addCase(querendRecordAccept.pending, (state, { payload }) => {
        state.actionLoading = false;
    })
    .addCase(querendRecordAccept.fulfilled, (state, { payload }) => {
        state.actionLoading = false;
    })
    .addCase(querendRecordAccept.rejected, (state, { payload }) => {
        state.actionLoading = false;
    })
    .addCase(querendPositionCancel.pending, (state, { payload }) => {

    })
    .addCase(querendPositionCancel.fulfilled, (state, { payload }) => {
            
    })
    .addCase(querendPositionCancel.rejected, (state, { payload }) => {

    })
    .addCase(querendAgreementListGet.pending, (state, { payload }) => {

    })
    .addCase(querendAgreementListGet.fulfilled, (state, { payload }) => {
            if(payload.error === false){state.agreementList = payload.data}
    })
    .addCase(querendAgreementListGet.rejected, (state, { payload }) => {

    })
  },
})

export const { setData } = querySlice.actions

export default querySlice.reducer