import { createSlice } from '@reduxjs/toolkit';

// Actions
import { getList, getSloAuthor, getSloMaterial, getSloTechnique, getSloTags, getSloType, getPeriodMaxMin } from '../actions/objects.actions';

const initialState = {
  listLoading: false,
  list: [],
  listOptions: {page: 1, onPage: 20, sort: 'name asc', pageSize: 0, sizeAll: 0, view: 'grid'},
  sloData: {
    author: {list: [], loading: false},
    material: {list: [], loading: false},
    technique: {list: [], loading: false},
    type: {list: [], loading: false},
    tags: {list: [], loading: false},
  },
  period: {min: 1500, max: 2023},
  searchTab: null,
  details: {data: null, dialog: false, btnAddToBasket: true}
}

const dynamicSort = (property) => {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

const objectsSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPage: (state, {payload}) => {
        state.listOptions = {...state.listOptions, page: payload};
    },
    setOnPage: (state, {payload}) => {
        state.listOptions = {...state.listOptions, onPage: payload, page: 1};
    },
    setSort: (state, {payload}) => {
        state.listOptions = {...state.listOptions, sort: payload};
    },
    setView: (state, {payload}) => {
        state.listOptions = {...state.listOptions, view: payload};
    },
    setList: (state, {payload}) => {
        state.list = payload;
    }, 
    addToSearchTab: (state, {payload}) => {
           let newTab = state.searchTab !== null ? [...state.searchTab] : [];
           if( payload !== undefined && payload !== null && payload.type !== undefined &&  payload.value !== undefined){
                if(payload.type === 'search' || payload.type === 'period'){
                    const ndx = newTab.findIndex(el => el.type === payload.type);
                    if(ndx > -1){
                        newTab[ndx].value = payload.value;
                        state.searchTab = newTab;
                    }else{
                        newTab.push(payload);
                        state.searchTab = newTab;
                        newTab.sort(dynamicSort("type"));
                    }
                }else{
                    const ndx = newTab.findIndex(el => el.type === payload.type && el.value === payload.value);
                    if(ndx){
                        newTab.push(payload);
                        state.searchTab = newTab;
                        newTab.sort(dynamicSort("type"));
                    }
                }
           }
    },
    removeFromSearchTab: (state, {payload}) => {
            let newTab = state.searchTab !== null ? [...state.searchTab] : [];

            if( payload !== undefined && payload !== null && payload.type !== undefined &&  payload.value !== undefined){
                    const ndx = newTab.findIndex(el => el.type === payload.type && el.value === payload.value);
                    if(ndx > -1){
                        newTab.splice(ndx, 1);
                        newTab.sort((a, b) => a.type - b.type);
                    }
           }
           state.searchTab = newTab;
    },
    removeFromSearchTabAll: (state, {payload}) => {
        let newTab = state.searchTab !== null ? [...state.searchTab] : [];

        if( payload !== undefined && payload !== null && payload !== ''){
                let ndx;
                while ((ndx = newTab.findIndex(el => el.type === payload)) > -1) {
                    newTab.splice(ndx, 1);
                }
       }
       state.searchTab = newTab;
},
    setSearchTab: (state, {payload}) => {
        state.searchTab = payload;
    },
    setDeatils: (state, {payload}) => {
        state.details = {...initialState.details,...payload};
    }, 
  },
  extraReducers: (builder) => {
     builder.addCase(getList.pending, (state, { payload }) => {
        state.listLoading = true
     })
    .addCase(getList.fulfilled, (state, { payload }) => {
        state.listLoading = false;
        if(payload.error === false){
            state.list = payload.data;
            state.listOptions = {...state.listOptions, sizeAll: payload.size, pageSize: Math.ceil(payload.size/state.listOptions.onPage)};
        }else{
            state.list = [];
            state.listOptions = {...state.listOptions, sizeAll: 0, pageSize: 0};
        }
    })
    .addCase(getList.rejected, (state, { payload }) => {
        state.listLoading = false;
    })
    .addCase(getSloAuthor.pending, (state, { payload }) => {
        state.sloData = {...state.sloData, author: {list: [], loading: true}};
     })
    .addCase(getSloAuthor.fulfilled, (state, { payload }) => {
        state.sloData = {...state.sloData, author: {list: payload.data, loading: false}};
        
    })
    .addCase(getSloAuthor.rejected, (state, { payload }) => {
        state.sloData = {...state.sloData, author: {list: [], loading: false}};
    })
    .addCase(getSloMaterial.pending, (state, { payload }) => {
        state.sloData = {...state.sloData, material: {list: [], loading: true}};
     })
    .addCase(getSloMaterial.fulfilled, (state, { payload }) => {
        state.sloData = {...state.sloData, material: {list: payload.data, loading: false}};
        
    })
    .addCase(getSloMaterial.rejected, (state, { payload }) => {
        state.sloData = {...state.sloData, material: {list: [], loading: false}};
    })
    .addCase(getSloTechnique.pending, (state, { payload }) => {
        state.sloData = {...state.sloData, technique: {list: [], loading: true}};
     })
    .addCase(getSloTechnique.fulfilled, (state, { payload }) => {
        state.sloData = {...state.sloData, technique: {list: payload.data, loading: false}};
        
    })
    .addCase(getSloTechnique.rejected, (state, { payload }) => {
        state.sloData = {...state.sloData, technique: {list: [], loading: false}};
    })
    .addCase(getSloTags.pending, (state, { payload }) => {
        state.sloData = {...state.sloData, tags: {list: [], loading: true}};
     })
    .addCase(getSloTags.fulfilled, (state, { payload }) => {
        state.sloData = {...state.sloData, tags: {list: payload.data, loading: false}};
        
    })
    .addCase(getSloTags.rejected, (state, { payload }) => {
        state.sloData = {...state.sloData, tags: {list: [], loading: false}};
    })
    .addCase(getSloType.pending, (state, { payload }) => {
        state.sloData = {...state.sloData, type: {list: [], loading: true}};
     })
    .addCase(getSloType.fulfilled, (state, { payload }) => {
        state.sloData = {...state.sloData, type: {list: payload.data, loading: false}};
        
    })
    .addCase(getSloType.rejected, (state, { payload }) => {
        state.sloData = {...state.sloData, type: {list: [], loading: false}};
    })
    .addCase(getPeriodMaxMin.fulfilled, (state, { payload }) => {
        state.period = payload.data;
        
    })
  },
})

export const { setPage, setOnPage, setSort, setList, addToSearchTab, removeFromSearchTab, removeFromSearchTabAll, setSearchTab, setInBasketAttr, setView, setDeatils } = objectsSlice.actions

export default objectsSlice.reducer