// JOY
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Actions

// Cnst
import { getFileSrc } from '../../middeware/prc.middleware';

//Images
import NoImage  from './../../images/noimg.png';

// Icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';

const webServiceURL = getFileSrc();

const ObjectCardSkeleton = ({item, onAddButtonClick, onRemoveButtonClick}) => {
    return  <Card variant="outlined">
                    <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
                        <Skeleton  />
                    </Typography>
                    <Typography level="body2"><Skeleton width="50%" /></Typography>
                    <AspectRatio minHeight="150px" maxHeight="150px" sx={{ my: 2 }} objectFit="contain">
                        <Skeleton height={150} />
                    </AspectRatio>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography level="body3"><Skeleton width={50} /></Typography>
                            <Skeleton height={30} width={120} />
                    </Box>
                </Card>
}

export default ObjectCardSkeleton;