import { createSlice } from '@reduxjs/toolkit';

// Actions
import { loginUser } from '../actions/auth.actions';

const initialState = {
  loading: false,
  userInfo: null,
  userToken: null,
  error: '',
  success: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action) => {
        localStorage.removeItem("muzeo_session");
        state.userInfo  = null;
        state.userToken = null;
    },
    initSession: (state, action) => {
        const newObj = typeof window !== "undefined" ? localStorage.getItem("muzeo_session") !== '' ? JSON.parse(localStorage.getItem("muzeo_session")) : null : null;
        if(newObj === null || newObj === undefined || newObj.token === undefined){
            localStorage.removeItem("muzeo_session");
            state.userInfo  = null;
            state.userToken = null;
        }else{
            state.userInfo  = newObj;
            state.userToken = newObj.token;
        }
        console.log('SESSION',newObj)
    },
  },
  extraReducers: (builder) => {
     builder.addCase(loginUser.pending, (state, { payload }) => {
        state.loading = true;
        state.error = '';
     })
    .addCase(loginUser.fulfilled, (state, { payload }) => {
        console.log(payload);
        if(payload.error === false){
            const data = payload.data;
            const userObj = {
                login: data.NICK,
                userRef: data.USER_REF,
                userName: data.USER_NAZ,
                userKoop: data.USKO_NA1,
                token: data.TOKEN,
                tokenExpire: data.TOKEN_TO
            };
            state.userInfo = userObj;
            state.userToken = data.TOKEN;
            state.success = true;
            state.error = '';
            localStorage.setItem("muzeo_session",JSON.stringify(userObj));
        }else{
            state.error = payload.text;
        }   

        state.loading = false
    })
    .addCase(loginUser.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
    })
  },
})

export const { initSession, logout } = authSlice.actions

export default authSlice.reducer