import { createSlice } from '@reduxjs/toolkit';

// Actions

const initialState = {
  listLoading: false,
  list: [],
  listOptions: {page: 1, onPage: 50, sort: 'N2 ASC'},
}

const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    setPage: (state, {payload}) => {
        state.listOptions = {...state.listOptions, page: payload};
    },
    setOnPage: (state, {payload}) => {
        state.listOptions = {...state.listOptions, onPage: payload};
    },
    setSort: (state, {payload}) => {
        state.listOptions = {...state.listOptions, sort: payload};
    },
    setList: (state, {payload}) => {
        state.list = payload;
    }, 
  },
  extraReducers: (builder) => {
  },
})

export const { setPage, setOnPage, setSort, setList } = summarySlice.actions

export default summarySlice.reducer