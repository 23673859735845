
// LOCALHOST
// export const settings = {
//     basePath: '/',
//     url: process.env.REACT_APP_URL,
//     webServiceUrl: process.env.REACT_APP_WEBSERVICE,
//     mail: process.env.REACT_APP_MAIL,
//     homeText: process.env.REACT_APP_HOME_TEXT

//   };

// SERWER CARGOS
// export const settings = {
//     basePath: '/',
//     url: 'http://192.168.8.181:8081/kwerendy',
//     webServiceUrl: 'http://localhost:7070/api',
//     mail: 'biuro@fpsystem.pl',
//     homeText: 'Jesteśmy bardzo zadowoleni, że tu jesteś i zapraszamy do odkrywania zbiorów zgromadzonych na naszej stronie. Nie wahaj się przeszukiwać i eksplorować naszych zasobów, tak by utworzyć zapytanie kwerendowe na miarę swoich potrzeb.'
// };

// MUZEM DOBRANOCEK
export const settings = {
    basePath: '/',
    url: 'http://kwerendy.muzeumdobranocek.pl',
    webServiceUrl: 'https://ns3111110.ip-37-59-54.eu/proxydob',
    mail: 'zbiory@muzeumdobranocek.pl',
    homeText: 'Jesteśmy bardzo zadowoleni, że tu jesteś i zapraszamy do odkrywania zbiorów zgromadzonych na naszej stronie. Nie wahaj się przeszukiwać i eksplorować naszych zasobów tak by utworzyć zapytanie kwerendowe na miarę swoich potrzeb.'
};
