import {useState, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';

// JOY
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { grey } from "@mui/material/colors";

// Components
import QueryStatus from './../../features/QueryStatus/QueryStatus'

// Actions
import { querendRecordGetList } from '../../actions/querend.actions';
import { useNavigate } from "react-router-dom";
import { Button, Divider } from "@mui/joy";



const NoPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = useSelector(state => state.query);
    const auth = useSelector(state => state.auth);

    return (
        <Box sx={{display: 'flex', height:'100vh', alignItems: 'center', justifyContent:'center', backgroundColor: grey[200]}}>
                    <Typography level='h1' sx={{my: 'auto'}}> Podana przez Ciebie strona nie istnieje</Typography>
        </Box>
    );
}

export default NoPage