import {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";


// JOY
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { grey } from '@mui/material/colors';
import Divider from '@mui/joy/Divider';
import Grid from '@mui/joy/Grid';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import Table from '@mui/joy/Table';
import AspectRatio  from '@mui/joy/AspectRatio';
import { IconButton, ListDivider, Stack } from "@mui/joy";
import Button from '@mui/joy/Button';
import Switch from '@mui/joy/Switch';
import Tooltip from '@mui/joy/Tooltip';


// Components
import QueryStatus from "../QueryStatus/QueryStatus";
import Skeleton from "react-loading-skeleton";
import { format, addDays } from "date-fns";
import { pl } from 'date-fns/locale';

// Icons
import PrintIcon from '@mui/icons-material/Print';
import ImageIcon from '@mui/icons-material/Image';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

import ReceiptIcon from '@mui/icons-material/Receipt';
import GetAppIcon from '@mui/icons-material/GetApp';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HandymanIcon from '@mui/icons-material/Handyman';
import SecurityIcon from '@mui/icons-material/Security';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import LocalSeeIcon from '@mui/icons-material/LocalSee';

// Cnst
import { getFileSrc } from '../../middeware/prc.middleware';

// Actions
import { setDeatils } from "../../reducers/objects.reducer";
import { setData } from "../../reducers/query.reducer";
import { querendPositionCancel } from "../../actions/querend.actions";

const webServiceURL = getFileSrc();

const QueryObjectsTable = ({refresh}) => {

    const query = useSelector(state => state.query);

    console.log('QuERY',query)

    const dispatch = useDispatch();

    const handleClickItem = (ev) => {
        const ref_nr = parseInt(ev.currentTarget.getAttribute("data-ref"));
        if(ref_nr !== undefined && ref_nr !== 0 && ref_nr !== null){
            const ndx = query.data.objects.findIndex(el => el.data.ref_nr === ref_nr);
            if(ndx > -1){
                dispatch(setDeatils({data: query.data.objects[ndx].data, dialog:true, btnAddToBasket:false}));
            }
        }
    }

    const handleCancelPosition = (ref_nr) => {
                dispatch(querendPositionCancel(ref_nr));
                const ndx = query.data.objects.findIndex(el => el.ref_nr === ref_nr);
                if(ndx > -1){
                    dispatch(setData(ndx));
                    refresh();
                }
    }


    return (<>
                <Sheet
                    variant="outlined"
                    sx={{
                        width: '100%',
                        maxHeight: '100%',
                        borderRadius: 'sm',
                        pt: 2,
                        pb: 4,
                        px: {xs: 1, md: 2},
                        my: 1,
                        overflowX: 'auto'
                    }}
                >
                    {query.data.objects !== undefined && query.data.objects !== null && query.data.objects.length > 0 ?
                    <Table 
                        aria-label="query-table" 
                        // size="lg"
                        stripe="odd"
                        stickyHeader
                        hoverRow
                        borderAxis="bothBetween"
                        sx={{
                            '& tr > *:last-child': {
                                position: {xs: 'inherit',md: 'sticky'},
                                right: 0,
                                bgcolor: 'var(--TableCell-headBackground)',
                            },

                            'tbody > tr > td:nth-child(2), tbody > tr > td:nth-child(1)': {
                                cursor: 'pointer'
                            }
                            }}
                    >
                        <thead>
                            <tr>
                                <th style={{width:80}}></th>
                                <th style={{width:300}}>Obiekt</th>
                                <th style={{width:170}}>Status</th>
                                <th style={{width:300}}>Kwerenda</th> 
                                <th style={{width:150}}>Czy wypożyczyć?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {query.data.objects.map(item => (
                                    <tr key={item.data.reference}>
                                    <td data-ref={item.data.ref_nr} onClick={handleClickItem}>
                                        <AspectRatio ratio="1" sx={{ width: 50, borderRadius: 'sm', m: '0 auto' }}>
                                            {item.data.mainFile.id_min !== undefined && item.data.mainFile.id_min !== '' ?
                                                <img
                                                    src={webServiceURL + '/'+item.data.mainFile.id_min}
                                                    srcSet={webServiceURL + '/'+item.data.mainFile.id_min}
                                                    loading="lazy"
                                                    alt={`Miniturka obiektu: ${item.data.name}`}
                                                />
                                            :
                                                <ImageIcon />
                                            }
                                        </AspectRatio>
                                    </td>
                                    <td data-ref={item.data.ref_nr} onClick={handleClickItem}>
                                        <Typography noWrap>{item.data.name}</Typography>
                                        <Typography level="body2" noWrap>
                                            {item.data.author}
                                        </Typography>
                                    </td>
                                    <td>
                                        <QueryStatus status={item.status} size="xs" />
                                    </td>
                                    <td>
                                        <Typography level="body2" noWrap>
                                            {item.description}
                                        </Typography>
                                        <Stack sx={{mt: '0.5rem', 'svg': {cursor: 'pointer'}}} direction="row" spacing={1}>
                                            {item.if_convoy > 0 && 
                                                <Tooltip arrow placement="top" size="sm" title="Wymagne konwojowanie">
                                                    <SecurityIcon sx={{mr: '0.2rem'}} />
                                                </Tooltip>   
                                            }
                                            {item.if_guarantee > 0 && 
                                                <Tooltip arrow placement="top" size="sm" title="Wymagane gwarancje">
                                                    <WorkspacePremiumIcon sx={{mr: '0.3rem'}} />
                                                </Tooltip>   
                                            }
                                            {item.if_guarantee > 0 && 
                                                <Tooltip arrow placement="top" size="sm" title="Wymaga digitalizacji">
                                                    <LocalSeeIcon sx={{mr: '0.3rem'}} />
                                                </Tooltip>   
                                            }
                                            <Divider orientation="vertical" />
                                            {item.license_cost > 0 && 
                                                <Tooltip arrow placement="top" size="sm" title="Koszt licencji">
                                                    <Box sx={{width:'fit-content', display: 'flex', alignItems: 'center'}}><ReceiptIcon sx={{mr: '0.3rem'}} />{item.license_cost} PLN </Box>
                                                </Tooltip>   
                                            }
                                             {item.get_cost > 0 && 
                                                <Tooltip arrow placement="top" size="sm" title="Koszt nabycia">
                                                    <Box sx={{width:'fit-content', display: 'flex', alignItems: 'center'}}><GetAppIcon sx={{mr: '0.3rem'}} />{item.get_cost} PLN </Box>
                                                </Tooltip>   
                                            }
                                             {item.transport_cost > 0 && 
                                                <Tooltip arrow placement="top" size="sm" title="Koszt transportu">
                                                    <Box sx={{width:'fit-content', display: 'flex', alignItems: 'center'}}><LocalShippingIcon sx={{mr: '0.3rem'}} />{item.transport_cost} PLN </Box>
                                                </Tooltip>   
                                            }
                                             {item.conservation_cost > 0 && 
                                                <Tooltip arrow placement="top" size="sm" title="Koszt konserwacji">
                                                    <Box sx={{width:'fit-content', display: 'flex', alignItems: 'center'}}><HandymanIcon sx={{mr: '0.3rem'}} />{item.conservation_cost} PLN </Box>
                                                </Tooltip>   
                                            }
                                            
                                        </Stack>
                                        <Stack sx={{mt: '0.5rem'}} direction="row" spacing={1}>
                                            
                                        </Stack>
                                    </td>
                                    <td>
                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                            <Switch
                                                variant="soft"
                                                color={item.if_akc === 1 && item.if_can === 0 ?'success' : 'danger'}    
                                                disabled={query.data.query.if_akc === 1  || (item.answer_stat !== 'v' && item.answer_stat !== '-')}
                                                sx={{
                                                    '--Switch-thumb-size': '27px',
                                                    '--Switch-track-width': '64px',
                                                    '--Switch-track-height': '31px',
                                                    }}                 
                                                slotProps={{
                                                    input: { 'aria-label': 'dark mode' },
                                                    track: {
                                                        children: (
                                                        <>
                                                            <CheckIcon sx={{  ml: '10px' }} />
                                                            <ClearIcon sx={{  mr: '10px' }} />
                                                        </>
                                                        ),
                                                    },
                                                    }}
                                                checked={item.if_akc === 1 && item.if_can === 0}
                                                onChange={() => handleCancelPosition(item.ref_nr)}
                                            />
                                        </Box>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    :
                        <Typography level="h5" textColor="text.tertiary">BRAK OBIEKTÓW W KWEENDZIE</Typography>
                    }
                </Sheet>

                
            </>  
    );
}

export default QueryObjectsTable;