import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// JOY
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import { grey } from '@mui/material/colors';

// Components
import ObjectCheckoutList from '../ObjectCheckoutList/ObjectCheckoutList';

// Icons
import SearchIcon from '@mui/icons-material/Search';
import HideImageIcon from '@mui/icons-material/HideImage';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

// Actions
import { removeFromList } from '../../reducers/checkout.reducer';
// Cnst
import { getFileSrc } from '../../middeware/prc.middleware';

//Images
import NoImage  from './../../images/noimg.png';
import { IconButton } from '@mui/joy';




const webServiceURL = getFileSrc();

const CheckoutDrawer = ({onDrawerClose}) => {

    const checkout = useSelector(state => state.checkout);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return <Box sx={{ p: '1rem', width:{xs:'100%', md: '20rem', lg: '25rem'}, height: '100vh', backgroundColor:grey[100]}}>
                <Typography level="h4" sx={{display: 'flex'}}>
                        Koszyk kwerendy 
                        <IconButton variant="plain" sx={{ml: 'auto'}} size="sm" onClick={onDrawerClose}>
                            <CloseIcon />
                        </IconButton>
                </Typography>
                {checkout.list.length === 0 ? 
                    <Typography level="body1" sx={{marginTop: '30vh', textAlign: 'center', p: '0 3rem'}}>Obecnie Twój koszyk kwerendy jest pusty. Przejdź do wyszukiwania, postaraj się odnaleźć
                    obiekty, które Cię interesują i dodaj je do koszyka.</Typography>
                  :
                    <>
                        <Typography level="body2" sx={{mb: '1rem'}}>Koszyk przedstawia wszystkie obiekty które zostały wybrane podczas kwerendy. Jeżeli chcesz sfinalizować
                        kwerendę i wysłać informacje o wybranych obiektach do naszego działu inwentaryzacji kliknij poniższy
                        przycisk.</Typography>
                        <Button
                            color="primary"
                            onClick={() => {onDrawerClose(); navigate('/summary');}}
                            variant="solid"
                            size="sm"
                            sx={{ml: 'auto'}}
                        >
                            Przejdź do podsumowania
                        </Button>
                        <Divider orientation="horizontal" sx={{m: '1rem 0'}} />
                        <Typography level="h5">Obiekty:</Typography>
                        <Sheet sx={{borderRadius: '12px',  height: 'calc(100vh - 280px)'}}>
                            <ObjectCheckoutList />
                        </Sheet>
                    </>

                }
                
                
            </Box>
}

export default CheckoutDrawer;