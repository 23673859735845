import React, {useEffect, useState, createRef} from 'react';
import { useDispatch, useSelector} from 'react-redux';

// JOY
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import IconButton from '@mui/joy/IconButton';
import Tooltip from '@mui/joy/Tooltip';
import CircularProgress from '@mui/joy/CircularProgress';

// Components
import PrismaZoom from 'react-prismazoom';

// Icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

// Images
import NoImage  from './../../images/noimg.png';

//Actions
import { getFileSrc } from '../../middeware/prc.middleware';

//scss
import './ImageViewer.css';

const ImageViewer = ({fileId,  sx={}, isNext, isPrev, onNext, onPrev}) => { 

    const prisma   = createRef();

    const [openLightbox, setOpenLightbox]           = useState(false);
    const [imageId, setImageId]                     = useState(null);
    const [imageLoaded, setImageLoaded]             = useState(false);
    const [btnZoomInVisible, setBtnZoomInVisible]   = useState(true);
    const [btnZoomOutVisible, setBtnZoomOutVisible] = useState(false);
    const [btnResetVisible, setBtnResetVisible]     = useState(false);
    const [opacity, setOpacity]                     = useState(1);

    useEffect(() => {
                setImageLoaded(false);
                if(fileId === undefined){setImageId(null)}else{setImageId([fileId])}
                if(prisma.current !== null){prisma.current.reset();}
    },[fileId]);


    const handleZoomIn = () => {
        prisma.current.zoomIn(1);
    }

    const handleFullScreen = () => {
        setOpenLightbox(true);
    }

    const handleDownload = () => {
        let link = document.createElement("a");
        link.href = getFileSrc()+'/'+imageId+'?d=true';
        link.click();
        document.body.removeChild(link);
    }

    const handleZoomOut = () => {
        prisma.current.zoomOut(1);
    }

    const handleReset = () => {
        prisma.current.reset();
    }

    const handleNext = () => {
        setImageLoaded(false);
        prisma.current.reset();
        if(onNext !== undefined){
            onNext();
        }
            
    }

    const handlePrev = () => {
        setImageLoaded(false);
        prisma.current.reset();
        if(onPrev !== undefined){
            onPrev();
        }
    }

    const handleZoomChange = (zoom) => {
        if(zoom > 4.5){
            setBtnZoomInVisible(false);
            setOpacity(0);
        }
        else if(zoom > 1){
            setBtnZoomOutVisible(true);
            setBtnResetVisible(true);
            setBtnZoomInVisible(true);
            setOpacity(0);
        }else{
            setBtnZoomOutVisible(false);
            setBtnResetVisible(false);
            setBtnZoomInVisible(true);
            setOpacity(1);
        }
    }

    const style = {...sx,height: sx.height !== undefined ? sx.height : '100%'}

    return (
        <Box sx={style} className="muzeo-object-images">
            {(imageId === null || imageId === '' || imageId[0] === undefined)  && <div>Niestety wybrana pozycja nie posiada wizerunku</div>}
            {imageId !== null && imageId !== '' &&
                <>
                    <PrismaZoom ref={prisma} onZoomChange={handleZoomChange} className="muzeo-object-image-prisma">
                        {imageLoaded === false && <CircularProgress />}
                        <img 
                            src={getFileSrc()+'/'+imageId} 
                            alt={'Wizerunek obiektu'}
                            style={{maxHeight: '100%', maxWidth: '100%', display: (imageLoaded ? 'block' : 'none')}}
                            onLoad={() => setImageLoaded(true)}
                        />
                    </PrismaZoom >
                   <Box className="muzeo-object-images-actions">
                        {btnZoomInVisible && 
                            <Tooltip describeChild title="Przybliż wizerunek" arrow placement="left">
                                <IconButton onClick={handleZoomIn} aria-label="przybliż">
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {btnZoomOutVisible && 
                            <Tooltip describeChild title="Oddal wizerunek" arrow placement="left">
                                <IconButton onClick={handleZoomOut} aria-label="oddal">
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {btnResetVisible && 
                            <Tooltip describeChild title="Zresetuj przybliżenie" arrow placement="left">
                                <IconButton onClick={handleReset} aria-label="zresetuj do ustawin pierwotnych">
                                    <ZoomOutMapIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip describeChild title="Pobierz aktualny wizerunek" arrow placement="left">
                            <IconButton aria-label="pobierz wizerunek" onClick={handleDownload}>
                                <FileDownloadIcon />
                            </IconButton>
                        </Tooltip>
                        {isNext && 
                            <Tooltip describeChild title="Następny wizerunek" arrow placement="left">
                                <IconButton aria-label="następny wizerunek" onClick={handleNext}>
                                    <NavigateNextIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {isPrev && 
                            <Tooltip describeChild title="Poprzedni wizerunek" arrow placement="left">
                                <IconButton aria-label="poprzedni wizerunek" onClick={handlePrev} size="large">
                                    <NavigateBeforeIcon />
                                </IconButton>
                            </Tooltip>
                        }
                   </Box>      
                </>
            }
        </Box>   
    );

}

export default ImageViewer;