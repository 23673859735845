import {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';


// JOY
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { grey } from '@mui/material/colors';
import Divider from '@mui/joy/Divider';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import Chip from '@mui/joy/Chip';
import Link from '@mui/joy/Link';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';

// Components
import ObjectCheckoutList from "../../features/ObjectCheckoutList/ObjectCheckoutList";
import CheckoutForm from "../../features/CheckoutForm/CheckoutForm";
import CheckoutSummary from "../../features/CheckoutSummary/CheckoutSummary";
import { useForm } from "react-hook-form";

// Icons
import SearchIcon from '@mui/icons-material/Search';
import ForwardIcon from '@mui/icons-material/Forward';
import { useNavigate } from "react-router-dom";
import { format, addDays } from "date-fns";
import { pl } from 'date-fns/locale';

// Actions
import { querendRecordPut } from "../../actions/querend.actions";
import { setReturnId } from "../../reducers/checkout.reducer";

const Summary = () => {

    const checkout = useSelector(state => state.checkout);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tab, setTab] = useState(0);

    const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm({defaultValues: {
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
        name: '',
        description: ''
    }});

    useEffect(() => {
        if(checkout.returnId !== ''){
            navigate('/query/'+checkout.returnId); 
        }
        return () => dispatch(setReturnId(''));
    }, [checkout.returnId])

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', height:'calc(100vh - 68px)', alignItems: 'center', backgroundColor: grey[200]}}>
             {checkout.list.length === 0 &&
                    <Box sx={{ p: 2, maxWidth: 500, mt: '4rem' }}>
                        <Typography level="h4">
                            Brak obiektów w koszyku kwerend
                        </Typography>
                        <Typography level="body1">
                            Obecnie twój koszyk jest pusty. Przejdź do <Link onClick={() => navigate('/find')}  endDecorator={<SearchIcon />}>katalogu obiektów</Link>, znajdź obiekty które Cię interesują i
                            dodaj je do koszyka. Następnie wróć na tę stronę i sfinalizuj kwerendę.
                        </Typography>
                    </Box>
            }
            {checkout.list.length > 0 &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height:'calc(100vh - 68px)',
                        width: {
                            xs: '100%',
                            md: 800
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            flexWrap: 'wrap',
                            p: '0 16px 8px 16px',
                            gap: 2,
                            mt: 2
                        }}
                    >
                        <Typography level="h5" textColor="text.primary">
                            Podsumowanie kwerendy
                        </Typography>
                        <Tabs
                            aria-label="Icon tabs"
                            sx={{ borderRadius: 'lg' }}
                            onChange={(ev,val) => setTab(val)}
                            value={tab}
                        >
                            <TabList >
                                <Tab value={0}>
                                    <Typography sx={{display: {xs: 'flex', md: 'none'}}}>Obiekty</Typography> 
                                    <Typography sx={{display: {xs: 'none', md: 'flex'}}}>Wybrane Obiekty</Typography> 
                                    <Chip size="sm" sx={{ ml: '0.5rem'}}>{checkout.list.length}</Chip>
                                </Tab>
                                <Tab  value={1}>
                                    <Typography sx={{display: {xs: 'flex', md: 'none'}}}>Cel</Typography>
                                    <Typography sx={{display: {xs: 'none', md: 'flex'}}}>Cel kwerendy</Typography>
                                </Tab>
                                <Tab  value={2}>
                                    <Typography>Podsumowanie</Typography>
                                </Tab>
                            </TabList>
                        </Tabs> 
                    </Box>
                    <Sheet
                        variant="outlined"
                        sx={{
                            width: '100%',
                            borderRadius: 'sm',
                            height:'calc(100% - 68px)',
                            p: {xs: 0.5,  md: 2},
                            mb: 1,
                        }}
                    >
                        <Box sx={{ p: 2, height: 'calc(100% - 50px)' }}>
                            {tab === 0 && 
                                <Box sx={{height: '100%'}}>
                                    <Typography level="h5" textColor="text.primary">
                                        Wybrane obiekty
                                    </Typography>
                                    <Typography level="body2" textColor="text.primary">
                                        Poniżej znajdują się wszystkie obiekty, które zostały wybrane podczas kwerendy. Możesz na tej stronie
                                        przejrzeć zawartość oraz zmodyfikować listę a następnie przejść do <Link onClick={() => setTab(1)} endDecorator={<ForwardIcon />}>
                                            wprowadzenia informacji o kwerendzie
                                        </Link>
                                    </Typography>
                                    <Divider sx={{width: '100%', m: '1rem 0'}} />
                                    <Box sx={{height: 'calc(100% - 100px)'}}>
                                        <ObjectCheckoutList />
                                    </Box>
                                </Box>
                            }
                            {tab === 1 &&
                             <Box sx={{height: '100%', overflow: 'auto'}}>
                                <Typography level="h5" textColor="text.primary">
                                    Informacje o kwerendzie
                                </Typography>
                                <Typography level="body2" textColor="text.primary" sx={{mb: 1}}>
                                    Proszę wprowadzić informacje dotyczące celu w jakim będą używane pozyskane obiekty, a następnie przejść do <Link onClick={() => setTab(2)} endDecorator={<ForwardIcon />}>
                                        Podsumowania kwerendy
                                    </Link>
                                </Typography>
                                <Typography level="body2" textColor="danger.400" sx={{mb: 1}}>
                                        Proszę wypełnić wszystkie poniższe pola.
                                </Typography>
                                <Divider sx={{width: '100%', m: '1rem 0'}} />
                                <Box>
                                    <CheckoutForm control={control} setValue={setValue} getValues={getValues} />
                                </Box>
                            </Box>
                            }
                            {tab === 2 && 
                                <CheckoutSummary getValues={getValues} handleSubmit={handleSubmit} setTab={setTab} />
                            }
                        </Box>
                    </Sheet>
                </Box>
            }
        </Box>
    );
}

export default Summary