import {
  Navigate,
  useLocation
} from 'react-router-dom';

import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children }) => {
  const {userToken, userInfo} = useSelector((state) => state.auth);
  const location = useLocation();

  if(userInfo === null){
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  const now = new Date();
  const expired = new Date(userInfo.tokenExpire);

  console.log('userToken')
  
    if (userToken === null || userToken === undefined || userToken === '' || userInfo === null || now > expired ) {
      return <Navigate to="/login" replace state={{ from: location }} />;
    }
  
    return children;
};

export default ProtectedRoute;