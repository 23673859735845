import { useDispatch, useSelector } from 'react-redux';

// JOY
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Grid from '@mui/joy/Grid';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import { grey } from '@mui/material/colors';

//Components
import ImageViewer from '../ImageViewer/ImageViewer';

// Actions
import { setDeatils } from '../../reducers/objects.reducer';
import { addToList, removeFromList } from '../../reducers/checkout.reducer';

// Cnst
import { getFileSrc } from '../../middeware/prc.middleware';

//Images
import NoImage  from './../../images/noimg.png';

// Icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const webServiceURL = getFileSrc();

const ObjectDetailsDialog = () => {
    const {details} = useSelector(state => state.objects);
    const checkout = useSelector(state => state.checkout);
    const dispatch  = useDispatch();

    const addToCheckout = (ev) => {
        if(details.data !== undefined &&  details.data !== null){
            dispatch(addToList({...details.data}));
        }
    }

    const removeFromCheckout = (ev) => {
        if(details.data !== undefined &&  details.data !== null){
            const ndx = checkout.list.findIndex(el => el.ref_nr === details.data.ref_nr);
            if(ndx > -1){
                dispatch(removeFromList(ndx));
            }
        }
    }

    const ifRemoveButton = details.data !== undefined && details.data !== null && checkout.list.findIndex(el => el.ref_nr === details.data.ref_nr) > -1;

    return  <Modal 
                open={details.dialog} 
                onClose={() => dispatch(setDeatils({data: null, dialog:false}))} 
                
            >
                <ModalDialog
                    aria-labelledby="layout-modal-title"
                    aria-describedby="layout-modal-description"
                    layout="center"
                    sx={{height: {xs: '100%', md: '100%', lg: '80%'}, width:{xs: '100%', sm: '100%', md: '100%', lg:960} }}
                >
                    <ModalClose />
                    {details.data !== null &&
                    <>
                        <Typography
                            id="layout-modal-title"
                            component="h2"
                            level="inherit"
                            fontSize="1.25em"
                            mb="0.1em"
                        >
                            {details.data.name}
                        </Typography>
                        <Divider sx={{width: '100%', my: 1}} />
                        <Grid container spacing={2} sx={{height: 'calc(100% - 30px)'}}>
                            <Grid xs={12} md={6} lg={6} xl={6} sx={{height: {xs:'50%', md:'100%'}}}>
                                <ImageViewer fileId={details.data.mainFile.id} />
                            </Grid>
                            <Grid xs={12} md={6} lg={6} xl={6} sx={{
                                height: {xs:'50%', md:'100%'},
                                overflow: 'auto', 
                                p:'1rem',
                                "::-webkit-scrollbar": {
                                    height: '8px',
                                    width: '8px',
                                  },
                                "::-webkit-scrollbar-track":{
                                    background: grey[200]
                                },
                                "::-webkit-scrollbar-thumb": {
                                    background: grey[400]
                                  }
                                }}>
                                <Box sx={{ display: 'flex', gap: 1, py: {xs: 1, md: 1} }}>
                                    { details.btnAddToBasket && ifRemoveButton &&
                                        <Button startDecorator={<RemoveIcon />} variant="soft" color="danger" onClick={removeFromCheckout}>
                                            Usuń z koszyka
                                        </Button>
                                    }
                                    { details.btnAddToBasket && ifRemoveButton === false && 
                                        <Button startDecorator={<AddIcon />} variant="soft" color="primary" onClick={addToCheckout}>
                                            Do koszyka
                                        </Button>
                                    }
                                </Box>
                                <Typography fontWeight="lg">Nazwa</Typography>
                                <Typography textColor="text.secondary">{details.data.name !== '' ? details.data.name : '---' }</Typography>
                                <Typography fontWeight="lg">Sygnatura</Typography>
                                <Typography textColor="text.secondary">{details.data.signature !== '' ? details.data.signature : '---' }</Typography>
                                <Typography fontWeight="lg">Autor/Wytwórna</Typography>
                                <Typography textColor="text.secondary">{details.data.author !== '' ? details.data.author : '---' }</Typography>
                                <Typography fontWeight="lg">Materiał/Tworzywo</Typography>
                                <Typography textColor="text.secondary">{details.data.material !== '' ? details.data.material : '---' }</Typography>
                                <Typography fontWeight="lg">Technika</Typography>
                                <Typography textColor="text.secondary">{details.data.technique !== '' ? details.data.technique : '---' }</Typography>
                                <Typography fontWeight="lg">Wymiary</Typography>
                                <Typography textColor="text.secondary">{details.data.dimension !== '' ? details.data.dimension : '---' }</Typography>
                                <Typography fontWeight="lg">Miejsce powstania</Typography>
                                <Typography textColor="text.secondary">{details.data.location !== '' ? details.data.location : '---' }</Typography>
                                <Typography fontWeight="lg">Okres powstania</Typography>
                                <Typography textColor="text.secondary">{details.data.period !== '' ? details.data.period : '---' }</Typography>
                                <Typography fontWeight="lg">Opis</Typography>
                                <Typography textColor="text.secondary">{details.data.description !== '' ? details.data.description : '---' }</Typography>
                                <Typography fontWeight="lg">Prawa autorskie</Typography>
                                <Typography textColor="text.secondary">{details.data.rights !== '' ? details.data.rights : '---' }</Typography>
                            </Grid>
                        </Grid>
                    </>
                    }
                    
                </ModalDialog>
            </Modal>
}

export default ObjectDetailsDialog;