// JOY
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';

// Actions
import { setDeatils } from '../../reducers/objects.reducer';

// Cnst
import { getFileSrc } from '../../middeware/prc.middleware';

//Images
import NoImage  from './../../images/noimg.png';

// Icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';

const webServiceURL = getFileSrc();

const ObjectCard = ({item, onAddButtonClick, onRemoveButtonClick, onClick}) => {
    const {list} = useSelector(state => state.checkout);

    const ifRemoveButton = list.findIndex(el => el.ref_nr === item.ref_nr) > -1;

    return  <Card variant="outlined" sx={{boxShadow: 'none'}}>
                    <Box data-ref={item.ref_nr} onClick={onClick} sx={{cursor: 'pointer'}}>
                        <Typography noWrap level="h2" fontSize="md" sx={{ mb: 0.5 }}>
                            {item.name !== '' ? item.name : '---'}
                        </Typography>
                        <Typography noWrap level="body2">{item.author !== '' ? item.author : '---'}</Typography>
                        <AspectRatio minHeight="150px" maxHeight="150px" sx={{ my: 2 }} objectFit="contain">
                                <img
                                    src={item.mainFile !== null && item.mainFile !== '' ? (webServiceURL + '/' +item.mainFile.id_min) : NoImage}
                                    srcSet={item.mainFile !== null && item.mainFile !== '' ? (webServiceURL + '/' +item.mainFile.id) : NoImage}
                                    loading="lazy"
                                    alt={`Miniturka karty obiektu: ${item.name}`}
                                />
                        </AspectRatio>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Box>
                            <Typography noWrap level="body3">{item.period !== '' ? item.period : '---'}</Typography>
                        </Box>
                        {ifRemoveButton === false ?
                            <Button
                                variant="soft"
                                size="sm"
                                color="primary"
                                aria-label="Dołącz obiekt do koszyka kwerendy"
                                startDecorator={<AddIcon />}
                                sx={{ ml: 'auto' }}
                                data-ref={item.ref_nr}
                                onClick={onAddButtonClick}
                            >
                                Do koszyka
                            </Button>
                        :
                            <Button
                                variant="soft"
                                size="sm"
                                color="danger"
                                aria-label="Usuń obiekt z koszyka kwerendy"
                                startDecorator={<RemoveIcon />}
                                sx={{ ml: 'auto' }}
                                data-ref={item.ref_nr}
                                onClick={onRemoveButtonClick}
                            >
                                Usuń z koszyka
                            </Button>
                        }
                    </Box>
                </Card>
}

export default ObjectCard;