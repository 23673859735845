import { extendTheme } from '@mui/joy/styles';

const theme = extendTheme({
    colorSchemes:{
        light: {
            palette: {
                primary:{
                    // 50: '#',
                    // 100: '#d5d2c3',
                    // 200: '#c4c0ab',
                    // 300: '#b4ae93',
                    // 400: '#a39c7b',
                    // 500: '#87805e',
                    // 600: '#787254',
                    // 700: '#605B43',
                    // 800: '#484532',
                    // 900: '#302E21',
                },
                background: {
                    backdrop: 'rgba(55,55,55,0.5)'
                }
            }
        }
    },
    components: {
        JoyModal: {
            defaultProps: {
               
              },
            styleOverrides: {
                backdropFilter: '4px',
            },
        },
      },
});

export default theme;