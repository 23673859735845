import { createAsyncThunk } from '@reduxjs/toolkit';

import {runPrc} from '../middeware/prc.middleware';

export const getList = createAsyncThunk(
  'object/getList',
  async (data, thunkApi) => {
        let wyn = {error: true, text: 'Nie udało się pobrać listy obiektów', data: [], size: 0};

        const state = thunkApi.getState();

        const session = state.auth;
        const objects = state.objects;
        const search = makeSearch((objects.searchTab !== null ? objects.searchTab : [] ));

        await runPrc("OB_listGet", "muzkwer", [session.userToken,search,objects.listOptions.page,objects.listOptions.onPage,objects.listOptions.sort]).then((data) => {
            if(data.length > 0 & data[0].WYNIK === 1){
                const jsonData = data.map(el => {
                    const jsonObj = JSON.parse(el.JSON);
                    return jsonObj;
                })
                wyn = {error: false, text: '', data: jsonData, size: data[0].SIZE}
            }else if(data.length > 0 & data[0].WYNIK === 0){
                wyn = {error: true, text: data[0].UWAGI, data: [], size: 0}
            }else{
                wyn = {error: true, text: 'Nie udało się pobrać listy obiektów', data: [], size: 0}
            }
        });

        return wyn;
  }
)

export const getSloAuthor = createAsyncThunk(
    'object/getSloAuthor',
    async (data, thunkApi) => {
          let wyn = {error: true, text: 'Nie udało się pobrać słownika autorów', data: [], size: 0};
  
          await runPrc("OB_getTwrTab", "muzkwer", []).then((data) => {
              if(data.length > 0){
                  const jsonData = data.map(el => {
                      return {name: el.NAZWA};
                  })
                  wyn = {error: false, text: '', data: jsonData, size: 0}
              }else{
                  wyn = {error: true, text: 'Słownik autorów nie posiada pozycji', data: [], size: 0}
              }
          });
  
          return wyn;
    }
);

export const getSloMaterial = createAsyncThunk(
    'object/getSloMaterial',
    async (data, thunkApi) => {
          let wyn = {error: true, text: 'Nie udało się pobrać słownika materiałów', data: [], size: 0};
  
          await runPrc("OB_getMatTab", "muzkwer", []).then((data) => {
              if(data.length > 0){
                  const jsonData = data.map(el => {
                      return {name: el.NAZWA};
                  })
                  wyn = {error: false, text: '', data: jsonData, size: 0}
              }else{
                  wyn = {error: true, text: 'Słownik materiałów nie posiada pozycji', data: [], size: 0}
              }
          });
  
          return wyn;
    }
)

export const getSloTechnique = createAsyncThunk(
    'object/getSloTechnique',
    async (data, thunkApi) => {
          let wyn = {error: true, text: 'Nie udało się pobrać słownika techniki', data: [], size: 0};
  
          await runPrc("OB_getTechTab", "muzkwer", []).then((data) => {
              if(data.length > 0){
                  const jsonData = data.map(el => {
                      return {name: el.NAZWA};
                  })
                  wyn = {error: false, text: '', data: jsonData, size: 0}
              }else{
                  wyn = {error: true, text: 'Słownik techniki nie posiada pozycji', data: [], size: 0}
              }
          });
  
          return wyn;
    }
)

export const getSloType = createAsyncThunk(
    'object/getSloType',
    async (data, thunkApi) => {
          let wyn = {error: true, text: 'Nie udało się pobrać słownika rodzaju', data: [], size: 0};
  
          await runPrc("OB_getRodzTab", "muzkwer", []).then((data) => {
              if(data.length > 0){
                  const jsonData = data.map(el => {
                      return {name: el.NAZWA};
                  })
                  wyn = {error: false, text: '', data: jsonData, size: 0}
              }else{
                  wyn = {error: true, text: 'Słownik rodzajów nie posiada pozycji', data: [], size: 0}
              }
          });
  
          return wyn;
    }
)

export const getSloTags = createAsyncThunk(
    'object/getSloTags',
    async (data, thunkApi) => {
          let wyn = {error: true, text: 'Nie udało się pobrać słownika słów kluczowych', data: [], size: 0};
  
          await runPrc("OB_getSkTab", "muzkwer", []).then((data) => {
              if(data.length > 0){
                  const jsonData = data.map(el => {
                      return {name: el.NAZWA};
                  })
                  wyn = {error: false, text: '', data: jsonData, size: 0}
              }else{
                  wyn = {error: true, text: 'Słownik słów kluczowych nie posiada pozycji', data: [], size: 0}
              }
          });
  
          return wyn;
    }
)

export const getPeriodMaxMin = createAsyncThunk(
    'object/getPeriodMaxMin',
    async (data, thunkApi) => {
          let wyn = {error: true, text: 'Nie udało się pobrać maksymalnych i minimalnych wartości okresu', data: []};
  
          await runPrc("OB_getOKMaxMin", "muzkwer", []).then((data) => {
              if(data.length > 0){
                  wyn = {error: false, text: '', data: {min: data[0].MIN_OK, max: data[0].MAX_OK}}
              }else{
                  wyn = {error: true, text: 'Nie udało się pobrać maksymalnych i minimalnych wartości okresu', data: []}
              }
          });
  
          return wyn;
    }
)
  

export const makeSearch = (tab) => {
    let wyn = '';
    const len = tab.length;
    if( len > 0){
      for(let i = 0; i < len; i++){
        if(tab[i].value !== ''){
            wyn += `${wyn !== '' ? '##' : ''}${tab[i].type}=${tab[i].value}`;
        } 
      }  
      
    } 
    return wyn;
  }