import { createAsyncThunk } from '@reduxjs/toolkit';
import {format} from 'date-fns';
import {runPrc} from '../middeware/prc.middleware';

export const querendRecordPut = createAsyncThunk(
  'querend/recordPut',
  async (data, thunkApi) => {
        let wyn = {error: true, text: 'Nie udało się zapisać kwerendy', data: []};

        const state = thunkApi.getState();

        const session = state.auth;
        const checkout = state.checkout;
        const refs = checkout.list.map((el) => el.ref_nr );
        const qyData = {...data, startDate: format(data.startDate,'yyyy/MM/dd'), endDate: format(data.endDate,'yyyy/MM/dd')}

        await runPrc("QY_recordPut", "muzkwer", [session.userToken,JSON.stringify(qyData),JSON.stringify(refs)]).then((data) => {
            if(data.length > 0 & data[0].WYNIK === 1){
                wyn = {error: false, text: '', data: '', id: data[0].ID}
            }else if(data.length > 0 & data[0].WYNIK === 0){
                wyn = {error: true, text: data[0].UWAGA, data: []}
            }else{
                wyn = {error: true, text: 'Nie udało się zapisać kwerendy', data: []}
            }
        });

        return wyn;
  }
)

export const querendRecordGet = createAsyncThunk(
    'querend/recordGet',
    async (id, thunkApi) => {
          let wyn = {error: true, text: 'Nie udało się pbrać kwerendy', data: null};
  
          const state = thunkApi.getState();
  
          const session = state.auth;
  
          await runPrc("QY_recordGet", "muzkwer", [session.userToken, id]).then((data) => {  
              if(data.length > 0 & data[0].WYNIK === 1){
                  wyn = {error: false, text: '', data: JSON.parse(data[0].JSON)}
              }else if(data.length > 0 & data[0].WYNIK === 0){
                  wyn = {error: true, text: data[0].UWAGA, data: null}
              }else{
                  wyn = {error: true, text: 'Nie udało się pobrać kwerendy', data: null}
              }
          });
  
          return wyn;
    }
  )


export const querendRecordGetList = createAsyncThunk(
    'querend/recordGetList',
    async (id, thunkApi) => {
          let wyn = {error: true, text: 'Nie udało się pobrać kwerendy', data: null};
  
          const state = thunkApi.getState();
  
          const session = state.auth;
  
          await runPrc("QY_recordGetList", "muzkwer", [session.userToken]).then((data) => {
              if(data.length > 0 & data[0].WYNIK === 1){
                const newData = data.map(el => JSON.parse(el.JSON));
                  wyn = {error: false, text: '', data: newData}
              }else if(data.length > 0 & data[0].WYNIK === 0){
                  wyn = {error: true, text: data[0].UWAGA, data: null}
              }else{
                  wyn = {error: true, text: 'Nie udało się pobrać kwerendy', data: null}
              }
          });
  
          return wyn;
    }
  )

export const querendRecordCancel = createAsyncThunk(
    'querend/recordCancel',
    async (id, thunkApi) => {
          let wyn = {error: true, text: 'Nie udało się anulować kwerendy', data: null};
  
          const state = thunkApi.getState();
  
          const session = state.auth;
  
          await runPrc("QY_recordCancel", "muzkwer", [session.userToken, id]).then((data) => {
            console.log('CANCEL',data)
              if(data.length > 0 & data[0].WYNIK === 1){
                  wyn = {error: false, text: ''}
              }else if(data.length > 0 & data[0].WYNIK === 0){
                  wyn = {error: true, text: data[0].UWAGA}
              }else{
                  wyn = {error: true, text: 'Nie udało się anulować kwerendy'}
              }
          });
  
          return wyn;
    }
  )

export const querendRecordAccept = createAsyncThunk(
    'querend/recordAcceppt',
    async (id, thunkApi) => {
          let wyn = {error: true, text: 'Nie udało się zaakceptować kwerendy', data: null};
  
          const state = thunkApi.getState();
  
          const session = state.auth;
  
          await runPrc("QY_recordAccept", "muzkwer", [session.userToken, id]).then((data) => {
            console.log('CANCEL',data)
              if(data.length > 0 & data[0].WYNIK === 1){
                  wyn = {error: false, text: ''}
              }else if(data.length > 0 & data[0].WYNIK === 0){
                  wyn = {error: true, text: data[0].UWAGA}
              }else{
                  wyn = {error: true, text: 'Nie udało się zaakceptować kwerendy'}
              }
          });
  
          return wyn;
    }
  )

export const querendPositionCancel = createAsyncThunk(
    'querend/positionCancel',
    async (id, thunkApi) => {
          let wyn = {error: true, text: 'Nie udało się ustalić wypożyczenia', data: null};
  
          const state = thunkApi.getState();
  
          const session = state.auth;
  
          await runPrc("QYKOB_recordCnc", "muzkwer", [session.userToken, id]).then((data) => {
              if(data.length > 0 & data[0].WYNIK === 1){
                  wyn = {error: false, text: ''}
              }else if(data.length > 0 & data[0].WYNIK === 0){
                  wyn = {error: true, text: data[0].UWAGA}
              }else{
                  wyn = {error: true, text: 'Nie udało się ustalić wypożyczenia'}
              }
          });
  
          return wyn;
    }
  )

export const querendAgreementListGet = createAsyncThunk(
    'querend/agreementListGet',
    async (id, thunkApi) => {
          let wyn = {error: true, text: 'Nie udało się pobrac umów', data: null};
  
          const state = thunkApi.getState();
  
          const session = state.auth;
  
          await runPrc("UL_listGet", "muzkwer", [session.userToken, id]).then((data) => {
              if(data.length > 0 & data[0].WYNIK === 1){
                  wyn = {error: false, text: '', data: data}
              }else if(data.length > 0 & data[0].WYNIK === 0){
                  wyn = {error: true, text: data[0].UWAGA}
              }else{
                  wyn = {error: true, text: 'Nie udało się pobrać umów'}
              }
          });
  
          return wyn;
    }
  )


