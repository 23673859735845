import { settings } from "./../config";

/*::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    OPIS: Uruchomienie procedury
      WE: prcName - nazwa procedury - STRING
          prcFile - nazwa pliku procedury - STRING
          data    - parametry procedury - OBIEKT
          token   - token ważny przy wywoływaniu procedury - STRING
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
export const runPrc = async (prcName, prcFile, data) => {
  // console.log(prcName);
  let body = "";
  let errorLog = {
    error: false,
    prcName: prcName,
    prcFile: prcFile,
    data: data,
    errorMsg: "",
  };
  if (prcName === undefined || prcFile === undefined) {
    return null;
  }

  if (data !== undefined) {
    const len = data.length;

    for (let i = 0; i < len; i++) {
      body +=
        (body !== "" ? "&" : "") +
        "par" +
        i +
        "=" +
        (typeof data[i] === "string" ? "'" : "") +
        data[i] +
        (typeof data[i] === "string" ? "'" : "");
    }
  }

  try {
    const response = await fetch(
      settings.webServiceUrl + "/prc/json/" + prcName + "/" + prcFile,
      {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        },
        body,
        credentials: "same-origin",
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorText = await response.text();
      errorLog.error = true;
      errorLog.errorMsg = JSON.parse(errorText).error;
      console.log(errorLog.errorMsg);
      return errorLog;
    }
  } catch (error) {
    console.log("ERROR: ", error);
    errorLog.error = true;
    errorLog.errorMsg = error;
    return errorLog;
  }
};

/*::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    OPIS: Pobranie plików
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
export const getFileSrc = () => {
  return settings.webServiceUrl + "/prc/getFileById";
};
