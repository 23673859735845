import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DeleteForever from '@mui/icons-material/DeleteForever';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Typography from '@mui/joy/Typography';

export default function ConfirmDialog({open, onClose, title = 'Uwaga', text = 'Czy na pewno chcesz?', acceptFun, acceptText = "OK", acceptColor = 'success'}) {

  return (
      <Modal open={open} onClose={onClose}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
          sx={{width: 400}}
        >
          <Typography
            id="alert-dialog-modal-title"
            component="h3"
          >
            {title}
          </Typography>
          <Divider />
          <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
            {text}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
            <Button variant="plain" color="neutral" onClick={onClose}>
              Anuluj
            </Button>
            <Button variant="soft" color={acceptColor} onClick={acceptFun}>
              {acceptText}
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
  );
}