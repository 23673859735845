import {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';

// JOY
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Textarea from '@mui/joy/Textarea';
import Input from '@mui/joy/Input';

// Components
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import pl from 'date-fns/locale/pl';
import {format, addDays} from 'date-fns';
import { Controller, useFormContext } from "react-hook-form";


import "react-datepicker/dist/react-datepicker.css";

// Icons
import SearchIcon from '@mui/icons-material/Search';
import ForwardIcon from '@mui/icons-material/Forward';
import { useNavigate } from "react-router-dom";
// Actions
import { setFormData } from "../../reducers/checkout.reducer"; 


registerLocale('pl', pl);

const CheckoutForm = ({setEnableSummary,setValue,control,getValues}) => {

    const checkout = useSelector(state => state.checkout);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return  <>
                <Box sx={{mb: '1rem', display: 'flex', rowGap: '2rem', flexDirection: {xs:'column', md: 'row'}}}>
                    <FormControl sx={{mr: '1rem'}}>
                        <FormLabel>Okres od</FormLabel>
                        <Controller
                            name={'startDate'}
                            control={control}
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                                formState,
                            }) => (
                                <DatePicker
                                    selected={value}
                                    onChange={(date) => onChange(date)}
                                    locale='pl'
                                    selectsStart
                                    startDate={getValues('startDate')}
                                    endDate={getValues('endDate')}
                                    dateFormat="yyyy/MM/dd"
                                    customInput={<Input placeholder="Data od" />}
                                />
                            )}
                        />
                       
                    </FormControl>
                    <FormControl>
                        <FormLabel>Okres do</FormLabel>
                        <Controller
                            name={'endDate'}
                            control={control}
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                                formState,
                            }) => (
                                <DatePicker
                                    selected={value}
                                    onChange={(date) => onChange(date)}
                                    locale='pl'
                                    selectsEnd
                                    startDate={getValues('startDate')}
                                    endDate={getValues('endDate')}
                                    dateFormat="yyyy/MM/dd"
                                    customInput={<Input placeholder="Data od" />}
                                />
                            )}
                        />
                    </FormControl>
                </Box>
                <FormControl sx={{mb: '1rem'}}>
                    <FormLabel>Nazwa celu</FormLabel>
                    <Controller
                        name={'name'}
                        control={control}
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                            formState,
                        }) => (
                            <Input placeholder="Wprowadź nazwę wystawy/celu" value={value} onChange={(ev) => onChange(ev)}/>
                        )}
                    />
                    
                </FormControl>
                <FormControl>
                    <FormLabel>Opis celu</FormLabel>
                    <Controller
                        name={'description'}
                        control={control}
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                            formState,
                        }) => (
                            <Textarea value={value} onChange={(ev) => onChange(ev)} placeholder="Wprowadź krótki opis w jakim celu będą użyte wybrane obiekty" minRows={5} />
                        )}
                    />
                    
                </FormControl>
            </>
}

export default CheckoutForm