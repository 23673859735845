import {useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { format} from "date-fns";
import { pl } from 'date-fns/locale';

// JOY
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import Link from '@mui/joy/Link';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import CircularProgress from '@mui/joy/CircularProgress';
import Alert from '@mui/joy/Alert';

// Components


// Icons
import ForwardIcon from '@mui/icons-material/Forward';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';


// Actions
import { querendRecordPut } from "../../actions/querend.actions";
import { setError } from "../../reducers/checkout.reducer";

const CheckoutSummary = ({getValues, handleSubmit, setTab}) => {

    const checkout = useSelector(state => state.checkout);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSave = (data) => {
        dispatch(querendRecordPut(data));
    }

    const handleRemoveError = () => {
        dispatch(setError(''));
    }
    

    return ( <Box sx={{height: '100%', overflow: 'auto'}}>
                <Typography level="h5" textColor="text.primary">
                    Podsumowanie
                </Typography>
                {getValues('name') !== '' && getValues('description') !== '' && getValues('startDate') !== null && getValues('endDate') !== null ?
                <>
                <Typography level="body2" textColor="text.primary">
                    Wszystkie wprowadzone przez Ciebie informacje zostaną wysłane do naszego działu inwentaryzacji. Pracownik po zapoznaniu się z zapytaniem, jak najszybciej wyśle odpowiedź na Twój adres e-mail. Możesz również śledzić status kwerendy wchodząc na tę stronę i podejrzeć kwerendę.
                </Typography>
                <Divider sx={{width: '100%', m: '1rem 0'}} />
                <Box>
                    <Typography mb={1} lineHeight="lg">
                        <Typography fontWeight="lg">Ilość obiektów:</Typography>   <Typography variant="outlined" color="success">{checkout.cartSize}</Typography>
                    </Typography>
                    <Typography mb={1}  lineHeight="lg">
                        <Typography fontWeight="lg">Okres:</Typography>  {`${format(getValues('startDate'),'dd MMMM yyyy',{locale: pl})} - ${format(getValues('endDate'),'dd MMMM yyyy',{locale: pl})}`}
                    </Typography>
                    <Typography lineHeight="lg">
                        <Typography fontWeight="lg">Cel:</Typography> {getValues('name')}
                    </Typography>
                    <Typography  lineHeight="lg">
                        {getValues('description')}
                    </Typography>
                </Box>
                <Divider sx={{width: '100%', m: '1rem 0'}} />
                { checkout.putLoading === false  ?
                    <Box sx={{textAlign: 'center'}}> 
                        {checkout.error !== '' && 
                            <Alert 
                                color="danger" 
                                size="md" 
                                startDecorator={<WarningIcon sx={{ mx: 0.5 }} />}
                                endDecorator={
                                    <>
                                      <IconButton variant="soft" size="sm" color="danger" onClick={handleRemoveError}>
                                        <CloseIcon />
                                      </IconButton>
                                    </>
                                  }
                            >
                                {checkout.error}
                            </Alert>
                        }
                        <Typography level="body2" textColor="text.primary" sx={{mb: 1}}>
                            Czy chcesz zakończyć kwerendę i wysłać ją do naszego działu inwentaryzacji?
                        </Typography>
                        <Button onClick={() => {handleSubmit(handleSave)()}}>Tak, wyślij</Button>
                    </Box>
                
                :
                    <Box sx={{textAlign: 'center'}}> 
                        <CircularProgress
                            color="primary"
                            size="lg"
                            variant="soft"
                            sx={{mb: 2}}
                        />
                        <Typography level="body2" textColor="text.primary" sx={{mb: 1}}>
                            Wysyłam informacje do naszego działu inwentaryzacji, Proszę czekać i nie zamykać okna przeglądarki.
                        </Typography>
                    </Box>
                }
                    
                </>
                :
                <>
                    <Typography level="body2" textColor="danger.400" sx={{mb: 1}}>
                        Nie zostały wypełnione wszystkie informacje związane z kwerendą. 
                    </Typography>
                    <Typography level="body2" textColor="text.primary" sx={{mb: 1}}>
                        Proszę uzupełnić dane w zakładce <Link onClick={() => setTab(1)} endDecorator={<ForwardIcon />}> Cel kwerendy </Link>
                    </Typography>
                </>
            }
        </Box>
    );
}

export default CheckoutSummary;