import {useState, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';

// JOY
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import { grey } from "@mui/material/colors";

// Components
import QueryStatus from './../../features/QueryStatus/QueryStatus'

// Actions
import { querendRecordGetList } from '../../actions/querend.actions';
import { useNavigate } from "react-router-dom";
import { Button, Divider } from "@mui/joy";



const MyQuery = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = useSelector(state => state.query);
    const auth = useSelector(state => state.auth);
    
    useEffect(() => {
        dispatch(querendRecordGetList());    
    }, [dispatch]);

    console.log(query);

    const handleGoToQuery = (ev) => {
        const qid = ev.currentTarget.getAttribute("data-qid");
        navigate('/query/' + qid);
    }



    return (
        <Box sx={{display: 'flex', height:'calc(100vh - 68px)', alignItems: 'center', justifyContent:'center', backgroundColor: grey[200]}}>
             <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height:'calc(100vh - 68px)',
                        width: {
                            xs: '100%',
                            md: 960
                        },
                    }}
                >
                    <Typography level="body1" letterSpacing={'0.1rem'} fontWeight="lg" sx={{mt: 2, ml: {xs: 1, md: 0}}}>MOJE KWERENDY</Typography>
                    <Sheet
                        variant="outlined"
                        sx={{
                            width: '100%',
                            borderRadius: 'sm',
                            height:'100%',
                            overflow: 'auto',
                            display: 'flex',
                            p: {xs:0, md :2},
                            mb: 3,
                            mt: 1
                        }}
                    >
                    {
                        (query.list === undefined || query.list === null || query.list.length === 0) &&
                            <Typography level="body2" fontWeight="lg" textAlign="center" sx={{mt: 2, mx: 'auto', maxWidth: 400}}>
                                Dla Twojej instytucji nie została utworzona jeszcze żadna kwerenda. Przejdź do <Link onClick={() => navigate('/find')}>Katalogu</Link> aby rozpocząc przeszukiwanie naszych zbiorów.
                            </Typography>
                    }
                    {query.list !== undefined && query.list !== null && query.list.length !== 0 &&
                        <Table 
                            aria-label="query-table" 
                            size="lg"
                            stripe="odd"
                            stickyHeader
                            hoverRow
                            borderAxis="bothBetween"
                            sx={{
                                "tbody > tr":{
                                    cursor: 'pointer'
                                }
                            }}
                        >
                            <thead>
                                <tr>
                                    <th style={{width:100}}>Data</th>
                                    <th style={{width:150}}>Sygantura</th>
                                    <th style={{width:300}}>Cel</th>
                                    <th style={{width:200}}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {query.list.map(el => (
                                    <tr key={el.id} data-qid={el.id} onClick={handleGoToQuery}>
                                        <td>
                                            {el.queryDate}
                                        </td>
                                        <td>
                                            {el.signature}
                                        </td>
                                        <td>
                                            {el.name}
                                        </td>
                                        <td>
                                            <QueryStatus status={el.status} size="xs" />
                                        </td>
                                    </tr>
                                ))}  
                            </tbody>
                        </Table>
                    }
                    </Sheet>
            </Box>
        </Box>
    );
}

export default MyQuery