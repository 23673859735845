import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
// import {
//   createBrowserRouter,
//   RouterProvider
// } from "react-router-dom";

import { Routes, Route, Outlet, Link } from "react-router-dom";

// Compontents
import ProtectedRoute from './features/ProtectedRoute/ProtectedRoute';
import TopBar from './features/TopBar/TopBar';
import Login from './app/pages/Login';
import Home from './app/pages/Home';
import SearchObjects from './app/pages/SearchObjects';
import Summary from './app/pages/Summary';
import Query from './app/pages/Query';
import MyQuery from './app/pages/MyQuery';
import NoPage from './app/pages/NoPage';

// JOY

// Actions
import { initSession } from './reducers/auth.reducer';

import './App.css';

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
      
  }, [dispatch]);

  return (
    <>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route path="find" element={<ProtectedRoute><SearchObjects /></ProtectedRoute>} />
          <Route path="summary" element={<ProtectedRoute><Summary /></ProtectedRoute>} />
          <Route path="query/:id" element={<ProtectedRoute><Query /></ProtectedRoute>} />
          <Route path="myquery" element={<ProtectedRoute><MyQuery /></ProtectedRoute>} />
        </Route>
        <Route path="*" element={<NoPage />} />
        <Route index element={<ProtectedRoute><Home /></ProtectedRoute>} />
      </Routes>
    </>
  );
}


const Layout = () => {
  return (
    <div>
      <TopBar />
      <Outlet />
    </div>
  );
}

export default App;
