import {useState, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// JOY
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import Alert from '@mui/joy/Alert';


// Actions
import { loginUser } from '../../actions/auth.actions';
import { logout } from '../../reducers/auth.reducer';

//Images
import backimg  from './../../images/loginb.jpg';
// import Logo  from './../../images/logo.jpg';
import Logo  from './../../images/logo_muzdob.png';
import { AspectRatio, Divider } from "@mui/joy";

// Const
import {settings} from '../../config';


const Login = () => {
    const dispatch = useDispatch();
    const [userLogin, setUserLogin] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const navigate = useNavigate();

    const { loading, userToken, error } = useSelector((state) => state.auth);

    useEffect(() => {
        if (userToken !== null) {
          navigate('/')
        }
      }, [navigate, userToken])

    useEffect(() => {
        dispatch(logout());
      }, [dispatch])

    const login = () => {
            dispatch(loginUser({userLogin,userPassword}))
    }   

    const handlePresEnterInput = (ev) => {
        if(ev.key === 'Enter'){
            login();
        }
    }
  
    return (
        <Box sx={{display: 'flex', height:'100vh', alignItems: 'center', justifyContent:'center', backgroundImage: `url(${backimg})`, backgroundSize: 'cover'}}>
                <Sheet
                    sx={{
                        width: 350,
                        ml: { xs: 'auto', sm: 'auto', md: '10%', lg: '20%'}, 
                        mr: 'auto',
                        my: 4, 
                        py: 3, 
                        px: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 'sm',
                        boxShadow: 'md',
                    }}
                    variant="outlined"
                    >
                    <div style={{width:'100%'}}>
                        <div style={{textAlign: 'center'}}>
                            <img
                                src={Logo}
                                srcSet={Logo}
                                loading="lazy"
                                alt={`Logo Muzeo`}
                                style={{width: 'fit-content', borderRadius: '12px', margin: '0 auto'}}
                            />
                        </div>
                        <Typography level="h5" component="h1" fontWeight='lg' textAlign="center" textTransform="uppercase" letterSpacing="0.1rem">
                            Kwerendy zewnętrzne
                        </Typography>
                        <Divider sx={{width: '100%', my: 1}} />
                        <Typography level="body2">Witaj, wprowadź dane do logowania by kontynuować. Jeżeli nie posiadasz odpowiednich danych skontaktuj się z naszym muzeum.</Typography>
                        <Typography level="body2" color={"primary"}>{settings.mail}</Typography>
                        {error !== '' && 
                            <Alert
                                color="warning"
                                size="sm"
                                variant="soft"
                                sx={{mt: 1}}
                            >
                                {error}
                            </Alert>
                        }
                    </div>
                    <FormControl>
                        <FormLabel>Login</FormLabel>
                        <Input
                            name="login"
                            type="login"
                            placeholder="login"
                            value={userLogin}
                            onKeyDown={handlePresEnterInput}
                            onChange={(ev) => {setUserLogin(ev.target.value)}}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Hasło</FormLabel>
                        <Input
                            name="password"
                            type="password"
                            placeholder="hasło"
                            value={userPassword}
                            onKeyDown={handlePresEnterInput}
                            onChange={(ev) => {setUserPassword(ev.target.value)}}
                        />
                    </FormControl>

                    <Button 
                        startDecorator={loading ? <CircularProgress variant="solid" thickness={2} /> : ''} 
                        disabled={loading} sx={{ mt: 1 }} 
                        onClick={login}
                        color="neutral"
                    >
                        Zaloguj
                    </Button>
                </Sheet>
        </Box>
    );
}

export default Login