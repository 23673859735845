import { createSlice } from '@reduxjs/toolkit';
import {format, addDays} from 'date-fns';

// Actions
import { querendRecordPut } from '../actions/querend.actions';

const initialState = {
  list: [],
  cartSize: 0,
  putLoading: false,
  error: '',
  returnId: ''
}

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setCartSize: (state, {payload}) => {
        state.cartSize = state.list.length;
    },
    setError: (state, {payload}) => {
        state.error = payload;
    },
    addToList: (state, {payload}) => {
        let newList = [...state.list];
        if( payload !== undefined && payload !== null && payload.ref_nr !== undefined &&
             newList.findIndex(el => el.ref_nr === payload.ref_nr) === -1){
            newList.push(payload);
            state.list = newList;
            state.cartSize = newList.length;
        }
        
    },
    removeFromList: (state, {payload}) => {
        if (payload > -1) {
            let newList = [...state.list];
            newList.splice(payload, 1);
            state.list = newList;
            state.cartSize = newList.length;
        }
    },
    setList: (state, {payload}) => {
        state.list = payload;
        state.cartSize = payload.length;
    },
    setReturnId: (state, {payload}) => {
      state.returnId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(querendRecordPut.pending, (state, { payload }) => {
      state.putLoading = true;
      state.error = '';
    })
    .addCase(querendRecordPut.fulfilled, (state, { payload }) => {
        state.putLoading = false;
        if(payload.error === true){
            state.error = payload.text;         
        }else{
          state.list = [];
          state.cartSize = 0;
          state.returnId = payload.id !== undefined ? payload.id : '';
        }
    })
    .addCase(querendRecordPut.rejected, (state, { payload }) => {
        state.putLoading = false;
        state.error = payload;
    })
  },
})

export const { setCartSize, addToList, removeFromList, setList, setFormData, setError, setReturnId} = checkoutSlice.actions

export default checkoutSlice.reducer