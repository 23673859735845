// JOY
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Actions

// Cnst
import { getFileSrc } from '../../middeware/prc.middleware';

//Images
import NoImage  from './../../images/noimg.png';

// Icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';

const webServiceURL = getFileSrc();

const ObjectCardListSkeleton = ({item, onAddButtonClick, onRemoveButtonClick}) => {
    return  <Card variant="soft" orientation="horizontal" sx={{ width: {sm: '100%', md: '70%'}, ml: {md: 3}, boxShadow: 'none', bgcolor: 'initial', '--Card-padding': '0px', }}>

    <Box sx={{width: '200px'}}>
        <Skeleton width="200px" height="200px" style={{borderBottomLeftRadius: '12px', borderTopLeftRadius: '12px'}} />
    </Box>
        <Box sx={{cursor: 'pointer', ml: 2, width: '100%', display: 'flex', flexDirection:'column'}}>
            <Divider sx={theme => ({width: '100%', "--Divider-thickness": '1px'})} />
            <Typography noWrap level="h2" fontSize="md" sx={{ my: 1 }}>
                <Skeleton width="400px" />
            </Typography>
            <Typography noWrap level="body2"><Skeleton width="200px" /></Typography>
            <Typography noWrap level="body2"><Skeleton width="100px" /></Typography>
            <Box sx={{ mt:'auto', display: 'flex', flexDirection: 'column'}}>
                <Box sx={{ display: 'flex', mt: 1}}>
                    <Skeleton height={30} width={120} style={{height: 'fit-content', borderRadius: '8px'}} />
                    <Skeleton height={30} width={100} style={{marginLeft: '1rem', height: 'fit-content', borderRadius: '8px'}} />
                </Box>
                <Divider sx={theme => ({mt: 1, width: '100%', "--Divider-thickness": '1px'})} />
            </Box>
        </Box>
    </Card>
}

export default ObjectCardListSkeleton;