import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// JOY
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListDivider from '@mui/joy/ListDivider';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemButton from '@mui/joy/ListItemButton';
import IconButton from '@mui/joy/IconButton';
import Avatar from '@mui/joy/Avatar';
import AspectRatio from '@mui/joy/AspectRatio';
import { grey } from '@mui/material/colors';

// Components
import { Virtuoso } from 'react-virtuoso';

// Icons
import SearchIcon from '@mui/icons-material/Search';
import HideImageIcon from '@mui/icons-material/HideImage';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';

// Actions
import { removeFromList } from '../../reducers/checkout.reducer';
import { setDeatils } from '../../reducers/objects.reducer';
// Cnst
import { getFileSrc } from '../../middeware/prc.middleware';

//Images
import NoImage  from './../../images/noimg.png';



const webServiceURL = getFileSrc();

const ObjectCheckoutList = ({onDrawerClose}) => {

    const checkout = useSelector(state => state.checkout);
    const dispatch = useDispatch();

    const removeFromCheckout = (ev) => {
        const ref_nr = parseInt(ev.currentTarget.getAttribute("data-ref"));
        if(ref_nr !== undefined && ref_nr !== 0 && ref_nr !== null){
            const ndx = checkout.list.findIndex(el => el.ref_nr === ref_nr);
            if(ndx > -1){
                dispatch(removeFromList(ndx));
            }
        }
    }

    const handleClickItem = (ev) => {
        const ref_nr = parseInt(ev.currentTarget.getAttribute("data-ref"));
        if(ref_nr !== undefined && ref_nr !== 0 && ref_nr !== null){
            const ndx = checkout.list.findIndex(el => el.ref_nr === ref_nr);
            if(ndx > -1){
                dispatch(setDeatils({data: checkout.list[ndx], dialog:true}));
            }
        }
    }

    return  <List
                aria-labelledby="ellipsis-list-demo"
                sx={{ '--List-decorator-size': '56px', height: '100%' }}
            >
                <Virtuoso 
                    style={{ height: '100%' }} 
                    data={checkout.list}
                    increaseViewportBy={300}
                    itemContent={(index, item) =>  <>
                                            <ListItem
                                                endAction={
                                                    <IconButton aria-label="Delete" size="sm" color="danger" data-ref={item.ref_nr} onClick={removeFromCheckout}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    }
                                                sx={{pr: '0.5rem'}}
                                            >
                                                <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
                                                    <AspectRatio  
                                                        minHeight={50} 
                                                        maxHeight={50}
                                                        variant="outlined"
                                                        ratio="4/3"
                                                        sx={{
                                                          width: 50,
                                                          bgcolor: 'background.level2',
                                                          borderRadius: 'md',
                                                        }}
                                                    >
                                                        {item.mainFile.id_min !== undefined && item.mainFile.id_min !== '' ?
                                                            <img
                                                                src={webServiceURL + '/'+item.mainFile.id_min}
                                                                srcSet={webServiceURL + '/'+item.mainFile.id_min}
                                                                loading="lazy"
                                                                alt={`Miniturka obiektu: ${item.name}`}
                                                            />
                                                        :
                                                            <ImageIcon />
                                                        }
                                                    </AspectRatio>
                                                </ListItemDecorator>
                                                <ListItemContent sx={{cursor: 'pointer'}} data-ref={item.ref_nr} onClick={handleClickItem}>
                                                    <Typography>{item.name}</Typography>
                                                    <Typography level="body2" noWrap>
                                                        {item.author !== '' ? item.author : '---'}
                                                    </Typography>
                                                </ListItemContent>
                                            </ListItem>
                                            <ListDivider sx={{width: '100%'}} />
                                        </>
                } 
                />
        </List>
}

export default ObjectCheckoutList;