import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// JOY
import Typography from '@mui/joy/Typography';

// Components

// Actions


// Icons

const QueryStatus = ({status ='', size = 'xs', sx = {}}) => {
    const stat = status.toLowerCase();
    const color = stat === 'odpisana' || stat === 'odpowiedziana' || stat === 'zgoda' || stat === 'przyjęta' ? 'success'
                  : stat === 'aktywowana do oceny' || stat === 'zgoda warunkowa' ? 'warning'
                  : stat === 'odmowa' || stat === 'anulowana' ? 'danger'
                  : 'primary'
    return <Typography sx={{width: 'fit-content', letterSpacing: '0.1rem', ...sx}} color={color} variant="outlined" fontSize={size === 'xs' ? '0.7rem'  : size === 'sm' ? '0.8rem' : '0.9rem'}>{status.toUpperCase()}</Typography>
}

export default QueryStatus;