import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authReducer from '../reducers/auth.reducer';
import objectsReducer from '../reducers/objects.reducer';
import checkoutReducer from '../reducers/checkout.reducer';
import summaryReducer from '../reducers/summary.reducer';
import queryReducer from '../reducers/query.reducer';

import { initSession } from '../reducers/auth.reducer';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    objects: objectsReducer,
    checkout: checkoutReducer,
    summary: summaryReducer,
    query: queryReducer
  },
});

store.dispatch(initSession());

export default store;
